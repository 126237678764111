import React from 'react';
import styled from 'styled-components';
import { Typography, IconButton } from '@mui/material';
import {
  HeaderLogoSvg,
  XSvg,
  TelegramSvg,
  MediumCircleFillSvg,
} from 'assets/img';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  DATLink,
  iDAOLink,
  TechLink,
  LAVLink,
  TelegramLink,
  MediumLink,
  XLink,
} from 'constant';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #fff;
  padding: 40px 32px;
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #fff;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`;

const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;
  gap: 16px;
`;

const MobileColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 40px 24px;
  gap: 8px;
`;

const FooterTitle = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
`;

const MobileLinkBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

const FooterLink = styled.a`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const FooterRouterLink = styled(Link)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 40px;
`;

const MobileBottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 24px;
`;

const Logo = styled(HeaderLogoSvg)`
  width: 128px;
  height: auto;
`;

const CopyrightBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px 24px 20px 24px;
`;

const Copyright = styled(Typography)`
  color: #c4c4c4;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 16px;
`;

const X = styled(XSvg)`
  width: 24px;
  height: auto;
`;

const Telegram = styled(TelegramSvg)`
  width: 24px;
  height: auto;
`;

const MediumCircleFill = styled(MediumCircleFillSvg)`
  width: 24px;
  height: auto;
`;

export default function Footer() {
  const navigate = useNavigate();

  const handleFaqClick = () => {
    navigate('/faq');
  };

  return isMobile ? (
    <MobileWrapper>
      <MobileContainer>
        <MobileColumn>
          <FooterTitle>LazAI Network</FooterTitle>
          <MobileLinkBox>
            <FooterLink target="_blank" href={DATLink}>
              DAT
            </FooterLink>
            <FooterLink target="_blank" href={iDAOLink}>
              iDAO
            </FooterLink>
          </MobileLinkBox>
        </MobileColumn>
        <MobileColumn>
          <FooterTitle>Docs</FooterTitle>
          <MobileLinkBox>
            <FooterLink target="_blank" href={TechLink}>
              Tech Lightpaper
            </FooterLink>
            <FooterLink target="_blank" href={LAVLink}>
              LAV Lightpaper
            </FooterLink>
          </MobileLinkBox>
        </MobileColumn>
        <MobileColumn>
          <FooterTitle>About</FooterTitle>
          <MobileLinkBox>
            <FooterRouterLink to="/terms">Terms & Conditions</FooterRouterLink>
            <FooterRouterLink to="/faq">FAQ</FooterRouterLink>
          </MobileLinkBox>
        </MobileColumn>
      </MobileContainer>
      <MobileBottomRow>
        <Logo />
        <SocialIcons>
          <IconButton href={TelegramLink} target="_blank" color="inherit">
            <Telegram />
          </IconButton>
          <IconButton href={MediumLink} target="_blank" color="inherit">
            <MediumCircleFill />
          </IconButton>
          <IconButton href={XLink} target="_blank" color="inherit">
            <X />
          </IconButton>
        </SocialIcons>
      </MobileBottomRow>

      <CopyrightBox>
        <Copyright>© 2024 LazAI Network</Copyright>
      </CopyrightBox>
    </MobileWrapper>
  ) : (
    <Wrapper>
      <Container>
        <Column>
          <FooterTitle>LazAI Network</FooterTitle>
          <FooterLink target="_blank" href={DATLink}>
            DAT
          </FooterLink>
          <FooterLink target="_blank" href={iDAOLink}>
            iDAO
          </FooterLink>
        </Column>
        <Column>
          <FooterTitle>Docs</FooterTitle>
          <FooterLink target="_blank" href={TechLink}>
            Tech Lightpaper
          </FooterLink>
          <FooterLink target="_blank" href={LAVLink}>
            LAV Lightpaper
          </FooterLink>
        </Column>
        <Column>
          <FooterTitle>About</FooterTitle>
          <FooterRouterLink to="/terms">Terms & Conditions</FooterRouterLink>
          <FooterRouterLink to="/faq">FAQ</FooterRouterLink>
        </Column>
      </Container>
      <BottomRow>
        <Logo />
        <Copyright>© 2024 LazAI Network</Copyright>
        <SocialIcons>
          <IconButton href={TelegramLink} target="_blank" color="inherit">
            <Telegram />
          </IconButton>
          <IconButton href={MediumLink} target="_blank" color="inherit">
            <MediumCircleFill />
          </IconButton>
          <IconButton href={XLink} target="_blank" color="inherit">
            <X />
          </IconButton>
        </SocialIcons>
      </BottomRow>
    </Wrapper>
  );
}
