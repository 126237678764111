import React from 'react';
import styled from 'styled-components';
import { Typography, Button } from '@mui/material';
import {
  MovingSvg,
  PicwizAvatarSvg,
  iDaoAvatarSvg,
  HaloSvg,
  MobilePicwizAvatarSvg,
  MobileIDaoAvatarSvg,
  IdentityPlatformSvg,
  DiversityIconSvg,
  PointIconSvg,
} from 'assets/img';
import HoverButton from 'components/HoverButton';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import { useInView } from 'react-intersection-observer';
import TextAnimation from 'components/TextAnimation';
import CountUp from 'react-countup';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: url(${require('assets/img/halo.png')}) no-repeat center center;
  background-size: 100% 100%;
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 100px 28px;
  gap: 28px;
  background: url(${require('assets/img/mobile_halo.png')}) no-repeat;
  background-size: 100% auto;
`;

const Halo = styled(HaloSvg)`
  width: 100%;
  height: auto;
  position: absolute;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  min-width: 1200px;
  max-width: 1400px;
  padding: 100px;
  gap: 40px;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: Syne;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
`;

const MobileTitle = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: Syne;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
`;

const Line = styled(motion.div)`
  height: 4px;
  width: 56px;
  background: var(
    --red-gradient,
    linear-gradient(90deg, #ff229f 0%, #ff4570 53.5%, #ff7300 100%)
  );
`;

const StatsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StatCard = styled(motion.div)`
  display: flex;
  flex-direction: column;
  padding: 40px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.1);
`;

const MobileStatCard = styled(motion.div)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
`;

const StatCardTitle = styled(Typography)`
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const MobileStatCardTitle = styled(Typography)`
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const StatCardPointBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const StatCardPoint = styled.div`
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const MobileStatCardPoint = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const StatCardPointIcon = styled(PointIconSvg)`
  width: 36px;
  height: auto;
`;

const MobileStatCardPointIcon = styled(PointIconSvg)`
  width: 21px;
  height: auto;
`;

const IndexBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

const Moving = styled(MovingSvg)`
  width: 28px;
  height: auto;
`;

const IdentityPlatform = styled(IdentityPlatformSvg)`
  width: 24px;
  height: auto;
`;

const DiversityIcon = styled(DiversityIconSvg)`
  width: 24px;
  height: auto;
`;

const PointIcon = styled(PointIconSvg)`
  width: 21px;
  height: auto;
`;

const MobilePointIcon = styled(PointIconSvg)`
  width: 18px;
  height: auto;
`;

const StatCardIndex = styled(Typography)`
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: var(
    --red-gradient,
    linear-gradient(90deg, #ff229f 0%, #ff4570 53.5%, #ff7300 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const IDAOList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
`;

const MobileIDAOList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const IDAOCard = styled(motion.div)`
  display: flex;
  width: 100%;
  padding: 40px;
  align-items: flex-start;
  gap: 40px;
  border-radius: 24px;
  border: 2px solid #1e53c4;
  background: linear-gradient(
    90deg,
    rgba(29, 39, 78, 0.5) 0%,
    rgba(14, 24, 62, 0.2) 51.5%,
    rgba(13, 22, 60, 0.24) 69.08%,
    rgba(0, 9, 46, 0.5) 100%
  );
  backdrop-filter: blur(9.449999809265137px);
`;

const MobileIDAOCard = styled(motion.div)`
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  gap: 24px;
  border-radius: 24px;
  border: 2px solid #1e53c4;
  background: linear-gradient(
    90deg,
    rgba(29, 39, 78, 0.5) 0%,
    rgba(14, 24, 62, 0.2) 51.5%,
    rgba(13, 22, 60, 0.24) 69.08%,
    rgba(0, 9, 46, 0.5) 100%
  );
  backdrop-filter: blur(9.449999809265137px);
`;

const PicwizAvatar = styled(PicwizAvatarSvg)`
  width: 310px;
  height: auto;
`;

const IDaoAvatar = styled(iDaoAvatarSvg)`
  width: 310px;
  height: auto;
`;

const MobilePicwizAvatar = styled(MobilePicwizAvatarSvg)`
  width: 100%;
  height: auto;
`;

const MobileIDaoAvatar = styled(MobileIDaoAvatarSvg)`
  width: 100%;
  height: auto;
`;

const IDAOCardContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 310px;
`;

const MobileIDAOCardContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const CardTitle = styled(Typography)`
  color: #fff;
  font-family: Syne;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
`;

const MobileCardTitle = styled(Typography)`
  color: #fff;
  font-family: Syne;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
`;

const CardStatsBox = styled.div`
  display: flex;
  flex-direction: row;
`;

const CardSubBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const CardPoint = styled(Typography)`
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 4px;
`;

const MobileCardPoint = styled.div`
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 4px;
`;

const CardIndex = styled(Typography)`
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: var(
    --red-gradient,
    linear-gradient(90deg, #ff229f 0%, #ff4570 53.5%, #ff7300 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 2px;
`;

const MobileCardIndex = styled(Typography)`
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: var(
    --red-gradient,
    linear-gradient(90deg, #ff229f 0%, #ff4570 53.5%, #ff7300 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 2px;
`;

const CardSubtitle = styled(Typography)`
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
`;

const MobileCardSubtitle = styled(Typography)`
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
`;

const CardDesc = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

const CardButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

const GradientButton = styled.div`
  position: relative;
  padding: 12px 32px;
  cursor: pointer;
  color: black;
  font-weight: 700;
  font-size: 16px;
  font-family: 'Syne', sans-serif;
  text-transform: none;
  height: 44px;
  border-radius: 22px;
  overflow: hidden;
  background-color: white;
  text-align: center;
`;

const ComingButton = styled.div`
  display: flex;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 39px;
  background: #363c54;
  height: 44px;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  font-family: 'Syne', sans-serif;
  text-transform: none;
  cursor: pointer;
  text-align: center;
`;

interface DiscoverProps {
  totalData: any;
}

const DiscoverIDAO: React.FC<DiscoverProps> = ({ totalData }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const lineVariants = {
    hidden: {
      width: '0%',
    },
    visible: {
      width: '56px',
      transition: {
        duration: 1,
        ease: 'easeInOut',
      },
    },
  };

  const fadeInVariants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
      filter: 'blur(10px)',
    },
    visible: (i: number) => ({
      opacity: 1,
      scale: 1,
      filter: 'none',
      transition: {
        delay: i * 0.5,
        duration: 1,
        ease: 'easeIn',
      },
    }),
  };

  const variants = {
    hidden: {
      opacity: 0,
      y: 150,
    },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.4,
        duration: 0.8,
      },
    }),
  };

  function isNotSafari() {
    const ua = navigator.userAgent;
    const isSafari = /Safari/.test(ua) && !/Chrome|Chromium/.test(ua);
    return !isSafari;
  }

  const handlePicwizClick = () => {
    window.open('https://t.me/PICWIZ_iDAO_bot', '_blank');
  };

  const joinCommiunity = () => {
    window.open('/loyaltyPoint', '_blank');
  };

  return isMobile ? (
    <MobileWrapper ref={ref}>
      <TitleBox>
        {inView ? (
          <TextAnimation
            text={`Discover\nYour Favorite iDAO`}
            fontSize="30px"
          />
        ) : (
          <Title></Title>
        )}

        <Line
          variants={lineVariants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
        />
      </TitleBox>
      <MobileStatCard
        variants={variants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        custom={0}
      >
        <MobileStatCardTitle>Engaging LazAI Loyalty Point</MobileStatCardTitle>
        <StatCardPointBox>
          <MobileStatCardPointIcon />
          <MobileStatCardPoint>
            {/* {inView ? (
              <CountUp
                start={0}
                end={totalData?.totalPoints ?? 0}
                duration={1.5}
                delay={1.5}
                separator=","
                style={{
                  fontFamily: '"Inter", sans-serif',
                  fontWeight: 600,
                }}
              />
            ) : (
              '0'
            )} */}
            {'- -'}
          </MobileStatCardPoint>
        </StatCardPointBox>
        <IndexBox>
          <Moving />
          {/* <StatCardIndex>{`+${
            totalData?.pointsRateOfReturn ?? 0
          }%`}</StatCardIndex> */}
          <StatCardIndex>{`${'-'}%`}</StatCardIndex>
        </IndexBox>
      </MobileStatCard>
      <MobileStatCard
        variants={variants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        custom={1}
      >
        <MobileStatCardTitle>Total Contributors</MobileStatCardTitle>
        <MobileStatCardPoint>
          {/* {inView ? (
            <CountUp
              start={0}
              end={totalData?.totalUsers ?? 0}
              duration={1.5}
              delay={1.5}
              separator=","
              style={{
                fontFamily: '"Inter", sans-serif',
                fontWeight: 600,
              }}
            />
          ) : (
            '0'
          )} */}
          {'- -'}
        </MobileStatCardPoint>
        <IndexBox>
          <Moving />
          {/* <StatCardIndex>{`+${
            totalData?.userRateOfReturn ?? 0
          }%`}</StatCardIndex> */}
          <StatCardIndex>{`${'-'}%`}</StatCardIndex>
        </IndexBox>
      </MobileStatCard>

      <MobileIDAOList>
        <MobileIDAOCard>
          <MobilePicwizAvatar />
          <MobileIDAOCardContent>
            <ContentBox>
              <MobileCardTitle>iDAO Picwiz</MobileCardTitle>
              <CardStatsBox style={{ gap: '16px' }}>
                <CardSubBox>
                  <PointIcon />
                  <MobileCardPoint>- -</MobileCardPoint>
                </CardSubBox>
                <CardStatsBox>
                  <Moving />
                  <MobileCardIndex>-%</MobileCardIndex>
                </CardStatsBox>
              </CardStatsBox>
              <CardSubBox>
                <IdentityPlatform />
                <MobileCardSubtitle>Created by LazAI Lab</MobileCardSubtitle>
              </CardSubBox>
              {/* <CardSubBox>
                <DiversityIcon />
                <MobileCardSubtitle>
                  900+Millions Contributors
                </MobileCardSubtitle>
              </CardSubBox> */}
              <CardDesc>
                In this iDAO centered around digital avatar social interaction,
                contributors are the creators of digital avatars. The AI Agent
                serves as the intelligent entity representing the consensus of
                all contributors.
              </CardDesc>
            </ContentBox>
          </MobileIDAOCardContent>
          {/* <HoverButton
            text="Enter Now"
            width="100%"
            height="44px"
            borderRadius="22px"
            fontSize="16px"
            onClick={handlePicwizClick}
          /> */}
          <ComingButton>Coming Soon</ComingButton>
          <GradientButton onClick={joinCommiunity}>
            Join Community
          </GradientButton>
        </MobileIDAOCard>

        {/* <MobileIDAOCard>
          <MobileIDaoAvatar />
          <MobileIDAOCardContent>
            <ContentBox>
              <MobileCardTitle>iDAO Picwiz</MobileCardTitle>
              <CardSubBox>
                <IdentityPlatform />
                <MobileCardSubtitle>Created by Nara</MobileCardSubtitle>
              </CardSubBox>
              <CardDesc>
                In this iDAO centered around digital avatar social interaction,
                contributors are the creators of digital avatars. The
                AI Agent serves as the intelligent entity representing the
                consensus of all contributors.
              </CardDesc>
            </ContentBox>
          </MobileIDAOCardContent>
          <ComingButton>Coming Soon</ComingButton>
        </MobileIDAOCard> */}
      </MobileIDAOList>
    </MobileWrapper>
  ) : (
    <Wrapper ref={ref}>
      {/* {isNotSafari() && <Halo />} */}
      {/* <Halo /> */}
      <Container>
        <TitleBox>
          {inView ? (
            <TextAnimation text="Discover Your Favorite iDAO" fontSize="48px" />
          ) : (
            <Title></Title>
          )}
          <Line
            variants={lineVariants}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
          />
        </TitleBox>
        <StatsWrapper>
          <StatCard
            style={{ width: '66%' }}
            variants={fadeInVariants}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            custom={0}
          >
            <StatCardTitle>Engaging LazAI Loyalty Point</StatCardTitle>
            <StatCardPointBox>
              <StatCardPointIcon />
              <StatCardPoint>
                {/* {inView ? (
                  <CountUp
                    start={0}
                    end={totalData?.totalPoints ?? 0}
                    duration={1.5}
                    delay={1.5}
                    separator=","
                    style={{
                      fontFamily: '"Inter", sans-serif',
                      fontWeight: 700,
                    }}
                  />
                ) : (
                  '0'
                )} */}
                {'- -'}
              </StatCardPoint>
            </StatCardPointBox>
            <IndexBox>
              <Moving />
              {/* <StatCardIndex>{`+${
                totalData?.pointsRateOfReturn ?? 0
              }%`}</StatCardIndex> */}
              <StatCardIndex>{`${'-'}%`}</StatCardIndex>
            </IndexBox>
          </StatCard>
          <StatCard
            style={{ width: '31%' }}
            variants={fadeInVariants}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            custom={1}
          >
            <StatCardTitle>Total Contributors</StatCardTitle>
            <StatCardPoint>
              {/* {inView ? (
                <CountUp
                  start={0}
                  end={totalData?.totalUsers ?? 0}
                  duration={1.5}
                  delay={1.5}
                  separator=","
                  style={{
                    fontFamily: '"Inter", sans-serif',
                    fontWeight: 600,
                  }}
                />
              ) : (
                '0'
              )} */}
              {'- -'}
            </StatCardPoint>
            <IndexBox>
              <Moving />
              {/* <StatCardIndex>{`+${
                totalData?.userRateOfReturn ?? 0
              }%`}</StatCardIndex> */}
              <StatCardIndex>{`${'-'}%`}</StatCardIndex>
            </IndexBox>
          </StatCard>
        </StatsWrapper>

        <IDAOList>
          <IDAOCard
            variants={variants}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            custom={2}
          >
            <PicwizAvatar />
            <IDAOCardContent>
              <ContentBox>
                <CardTitle>iDAO Picwiz</CardTitle>
                <CardStatsBox style={{ gap: '16px' }}>
                  <CardSubBox>
                    <PointIcon />
                    <CardPoint>- -</CardPoint>
                  </CardSubBox>
                  <CardStatsBox>
                    <Moving />
                    <CardIndex>-%</CardIndex>
                  </CardStatsBox>
                </CardStatsBox>
                <CardStatsBox style={{ gap: '40px' }}>
                  <CardSubBox>
                    <IdentityPlatform />
                    <CardSubtitle>Created by LazAI Lab</CardSubtitle>
                  </CardSubBox>
                  {/* <CardSubBox>
                    <DiversityIcon />
                    <CardSubtitle>900+Millions Contributors</CardSubtitle>
                  </CardSubBox> */}
                </CardStatsBox>
                <CardDesc>
                  In this iDAO centered around digital avatar social
                  interaction, contributors are the creators of digital avatars.
                  The AI Agent serves as the intelligent entity representing the
                  consensus of all contributors.
                </CardDesc>
              </ContentBox>
              <CardButtonBox>
                {/* <HoverButton
                  text="Enter Now"
                  width="175px"
                  height="44px"
                  borderRadius="22px"
                  fontSize="16px"
                  onClick={handlePicwizClick}
                /> */}
                <CardButtonBox>
                  <ComingButton>Coming Soon</ComingButton>
                </CardButtonBox>
                <GradientButton
                  style={{ width: '211px' }}
                  onClick={joinCommiunity}
                >
                  Join Community
                </GradientButton>
              </CardButtonBox>
            </IDAOCardContent>
          </IDAOCard>

          {/* <IDAOCard
            variants={variants}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            custom={2}
          >
            <IDaoAvatar />
            <IDAOCardContent>
              <ContentBox>
                <CardTitle>iDAO Picwiz</CardTitle>
                <CardStatsBox style={{ gap: '40px' }}>
                  <CardSubBox>
                    <IdentityPlatform />
                    <CardSubtitle>Created by Nara</CardSubtitle>
                  </CardSubBox>
                </CardStatsBox>
                <CardDesc>
                  In this iDAO centered around digital avatar social
                  interaction, contributors are the creators of digital
                  avatars. The AI Agent serves as the intelligent entity
                  representing the consensus of all contributors.
                </CardDesc>
              </ContentBox>
              <CardButtonBox>
                <ComingButton>Coming Soon</ComingButton>
              </CardButtonBox>
            </IDAOCardContent>
          </IDAOCard> */}
        </IDAOList>
      </Container>
    </Wrapper>
  );
};

export default DiscoverIDAO;
