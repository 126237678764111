import React from 'react';
import Button from '@mui/material/Button';
import styles from './HoverButton.module.css';

interface HoverButtonProps {
  text?: string;
  width?: string;
  height?: string;
  fontSize?: string;
  borderRadius?: string;
  zIndex?: number;
  children?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}

const HoverButton: React.FC<HoverButtonProps> = ({
  text,
  width = '100%',
  height = '52px',
  borderRadius = '16px',
  fontSize = '16px',
  zIndex = 1,
  children,
  disabled = false,
  onClick,
}) => {
  return (
    <Button
      className={styles.button}
      style={{ width, height, borderRadius, zIndex, fontSize }}
      disabled={disabled}
      onClick={!disabled ? onClick : undefined}
    >
      <span>{children || text}</span>
    </Button>
  );
};

export default HoverButton;
