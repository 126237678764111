import React, { useRef } from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const CardWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 40px 100px 0;
  overflow: hidden;
  width: 85%;
  height: 100%;
`;

const MobileCardWrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;

const ImageContainer = styled(motion.div)`
  /* flex: 1; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
`;

const MobileImageContainer = styled(motion.div)`
  top: 5vh;
  width: 80%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 3rem;
  gap: 10px;
  text-align: left;
`;

const MobileContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
  z-index: 1;
`;

const TitleBox = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 16px;
`;

const Title = styled(Typography)`
  color: #fff;
  font-family: Syne;
  font-size: 2.2rem;
  line-height: 54px;
  font-weight: 700;
`;

const MobileTitle = styled(Typography)`
  color: #fff;
  font-family: Syne;
  font-size: 32px;
  font-weight: 700;
  line-height: 34px;
`;

const Subtitle = styled(Typography)`
  color: #fff;
  font-family: Inter;
  font-size: 1.5rem;
  font-weight: 600;
`;

const MobileSubtitle = styled(Typography)`
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
`;

const Divider = styled(motion.div)`
  height: 1px;
  background-color: #3f3b3b;
  margin: 8px 0;
`;

const Description = styled(Typography)`
  color: #c4c4c4;
  font-family: Inter;
  font-size: 1.1rem;
`;

const MobileDescription = styled(Typography)`
  color: #c4c4c4;
  font-family: Inter;
  font-size: 14px;
`;

const Line = styled(motion.div)`
  height: 4px;
  background: var(
    --red-gradient,
    linear-gradient(90deg, #ff229f 0%, #ff4570 53.5%, #ff7300 100%)
  );
`;

interface MechanismCardProps {
  url: string;
  title: string;
  desc: string;
  content: {
    subtitle: string;
    description: string;
  }[];
  isAction?: boolean;
}

const MechanismCard: React.FC<MechanismCardProps> = ({
  url,
  title,
  desc,
  content,
  isAction,
}) => {
  const { ref, inView } = useInView({
    threshold: 0.3,
  });

  const fadeInVariants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
      filter: 'blur(10px)',
    },
    visible: (i: number) => ({
      opacity: 1,
      scale: 1,
      filter: 'none',
      transition: {
        delay: i * 1,
        duration: 1.5,
        ease: 'easeIn',
      },
    }),
  };

  const variants = {
    hidden: {
      opacity: 0,
      y: 80,
      filter: 'blur(10px)',
    },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      filter: 'none',
      transition: {
        delay: i * 0.1,
        duration: 0.3,
        ease: 'easeIn',
      },
    }),
  };

  const lineVariants = {
    hidden: {
      width: '0%',
    },
    visible: (i: number) => ({
      width: '56px',
      transition: {
        delay: i * 0.1,
        duration: 0.3,
        ease: 'easeInOut',
      },
    }),
  };

  const dividerVariants = {
    hidden: {
      width: '0%',
    },
    visible: (i: number) => ({
      width: '100%',
      transition: {
        delay: i * 0.1,
        duration: 0.3,
        ease: 'easeInOut',
      },
    }),
  };

  const contentVariants = {
    visible: {
      opacity: 1,
      scale: 1,
      filter: 'none',
    },
    hidden: {
      opacity: 0,
      scale: 0.95,
      filter: 'blur(10px)',
      transition: {
        duration: 0.5,
      },
    },
  };

  return isMobile ? (
    <MobileCardWrapper
      ref={ref}
      variants={contentVariants}
      initial="visible"
      animate={isAction ? 'hidden' : 'visible'}
    >
      <MobileImageContainer
        variants={fadeInVariants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        custom={0}
      >
        <video
          src={url}
          autoPlay
          loop
          muted
          playsInline
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </MobileImageContainer>
      <MobileContentContainer>
        <motion.div
          variants={variants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          custom={0}
        >
          <MobileTitle>{title}</MobileTitle>
        </motion.div>
        <Line
          variants={lineVariants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          custom={1}
        />
        <motion.div
          variants={variants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          custom={2}
        >
          <MobileDescription>{desc}</MobileDescription>
        </motion.div>
        {content.map((item, index) => (
          <div key={index}>
            <motion.div
              variants={variants}
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              custom={index + 3 + index * 3}
            >
              <MobileSubtitle>{item.subtitle}</MobileSubtitle>
            </motion.div>
            <Divider
              variants={dividerVariants}
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              custom={index + 4 + index * 3}
            />
            <motion.div
              variants={variants}
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              custom={index + 5 + index * 3}
            >
              <MobileDescription>{item.description}</MobileDescription>
            </motion.div>
          </div>
        ))}
      </MobileContentContainer>
    </MobileCardWrapper>
  ) : (
    <CardWrapper
      ref={ref}
      variants={contentVariants}
      initial="visible"
      animate={isAction ? 'hidden' : 'visible'}
    >
      <ImageContainer
        variants={fadeInVariants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        custom={0}
      >
        <video
          src={url}
          autoPlay
          loop
          muted
          playsInline
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </ImageContainer>
      <ContentContainer>
        <TitleBox>
          <motion.div
            variants={variants}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            custom={0}
          >
            <Title>{title}</Title>
          </motion.div>
          <Line
            variants={lineVariants}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            custom={1}
          />
        </TitleBox>
        <motion.div
          variants={variants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          custom={2}
        >
          <Description>{desc}</Description>
        </motion.div>
        {content.map((item, index) => (
          <div key={index}>
            <motion.div
              variants={variants}
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              custom={index + 3 + index * 3}
            >
              <Subtitle>{item.subtitle}</Subtitle>
            </motion.div>
            <Divider
              variants={dividerVariants}
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              custom={index + 4 + index * 3}
            />
            <motion.div
              variants={variants}
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              custom={index + 5 + index * 3}
            >
              <Description>{item.description}</Description>
            </motion.div>
          </div>
        ))}
      </ContentContainer>
    </CardWrapper>
  );
};

export default MechanismCard;
