import React, { useState } from 'react';
import { Typography, Button, IconButton } from '@mui/material';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper as SwiperClass } from 'swiper';
import TextAnimation from 'components/TextAnimation';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import {
  ArrowBackSvg,
  ArrowForwardSvg,
  LeftMaskSvg,
  RightMaskSvg,
} from 'assets/img';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TeamCard from 'components/TeamCard';

const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  aspect-ratio: 100 / 52;
  position: relative;
  margin-bottom: 160px;
`;

const BackgroundImageBox = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  opacity: 0.25;
`;

const MobileBackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  opacity: 0.25;
  object-fit: cover;
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 1;
`;

const Title = styled(Typography)`
  color: #fff;
  font-family: Syne;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  margin-left: 14px;
  height: 121.81px;
`;

const Line = styled(motion.div)`
  height: 4px;
  background: var(
    --red-gradient,
    linear-gradient(90deg, #ff229f 0%, #ff4570 53.5%, #ff7300 100%)
  );
  z-index: 999;
`;

const SwiperWrapper = styled(motion.div)`
  position: relative;
  margin-top: 140px;
  margin-bottom: 100px;
  width: 100vw;
  height: 520px;
  overflow: hidden;
  z-index: 1;

  .swiper {
    position: relative;
    z-index: 0 !important;
    overflow: visible;
  }

  .swiper-wrapper {
    overflow: visible;
    position: relative;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease, opacity 0.3s ease;
    width: 200px !important;
    height: 520px;
    z-index: 0 !important;
    position: relative;
  }

  .swiper-slide-active {
    transform: scale(2) !important;
  }
`;

const MobileSwiperWrapper = styled(motion.div)`
  position: relative;
  margin-top: 22vh;
  margin-bottom: 15vh;
  width: 100%;
  z-index: 1 !important;

  .swiper {
    position: relative;
    z-index: 0 !important;
    overflow: visible;
  }

  .swiper-wrapper {
    overflow: visible;
    position: relative;
  }

  .swiper-slide {
    position: relative;
    display: flex;
    align-items: center;
    width: 30vw !important;
    height: 90vw;
    transition: transform 0.3s ease, opacity 0.3s ease;
    z-index: 0 !important;
  }

  .swiper-slide-active {
    transform: scale(2) !important;
  }
`;

const TeamCardBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 200px;
  transition: filter 0.3s ease;

  height: 200px;
  border-radius: 0px 100px;
  filter: brightness(50%);
  overflow: hidden;

  .swiper-slide-active & {
    height: 260px;
    border: 8px solid #04f;
    filter: brightness(100%);
  }
`;

const MobileTeamCardBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 30vw;
  height: 30vw;
  border-radius: 0px 50px;
  transition: filter 0.3s ease;
  filter: brightness(50%);
  overflow: hidden;

  .swiper-slide-active & {
    height: 38vw;
    border: 4px solid #04f;
    filter: brightness(100%);
  }
`;

const LeftArrowButton = styled(motion.div)`
  position: absolute;
  top: 480px;
  left: calc(50% - 320px);
  z-index: 999;
`;

const RightArrowButton = styled(motion.div)`
  position: absolute;
  top: 480px;
  right: calc(50% - 320px);
  z-index: 999;
`;

const ArrowButton = styled(Button)`
  border-radius: 50%;
`;

const ArrowBack = styled(ArrowBackSvg)`
  width: 88px;
  height: auto;
`;

const ArrowForward = styled(ArrowForwardSvg)`
  width: 88px;
  height: auto;
`;

const MobileLeftArrowButton = styled(IconButton)`
  position: absolute;
  top: 60%;
  transform: translateY(-60%);
  left: calc(50% - 46vw);
  z-index: 9999 !important;
`;

const MobileRightArrowButton = styled(IconButton)`
  position: absolute;
  top: 60%;
  transform: translateY(-60%);
  right: calc(50% - 46vw);
  z-index: 9999;
`;

const MobileArrowBack = styled(ArrowBackSvg)`
  width: 44px;
  height: auto;
`;

const MobileArrowForward = styled(ArrowForwardSvg)`
  width: 44px;
  height: auto;
`;

const LeftMask = styled(LeftMaskSvg)`
  width: 700px;
  height: auto;
  position: absolute;
  z-index: 0;
  left: 0;
  top: -400px;
`;

const RightMask = styled(RightMaskSvg)`
  width: 800px;
  height: auto;
  position: absolute;
  z-index: 1;
  right: 0;
  top: -600px;
`;

const teamMembers = [
  {
    id: 0,
    name: 'Lelia Ames',
    role: 'Team Lead',
    desc: 'A girl seeking order in chaos, leading LazAI team to align AI with humanity.',
    image: require('assets/img/Lelia_Ames.png'),
  },
  {
    id: 1,
    name: 'Steven',
    role: (
      <>
        Team Coordinator
        <br />
        Co-founder of P1X
      </>
    ),
    desc: 'Steven with extensive experience in investment and incubation, and an expert in the blockchain industry.',
    image: require('assets/img/Steven.png'),
  },
  {
    id: 2,
    name: 'Daniel Lee',
    role: (
      <>
        Team Coordinator
        <br />
        Co-founder of P1X
      </>
    ),
    desc: 'A DeFi specialist with rich experience in blockchain projects.',
    image: require('assets/img/Daniel_Lee.png'),
  },
  {
    id: 3,
    name: 'Ming',
    role: (
      <>
        Narrative
        <br />
        focused on blockchain and
        <br />
        AI research
      </>
    ),
    desc: 'Ming leading the development of LazAI’s narrative.',
    image: require('assets/img/Ming.png'),
  },
  {
    id: 4,
    name: 'Ethan',
    role: 'Tech Lead',
    desc: "Ethan with over 10 years of experience in public chain development, responsible for designing LazAI's technical solutions.",
    image: require('assets/img/Jerome.png'),
  },
  {
    id: 5,
    name: 'Elena',
    role: (
      <>
        Advisor
        <br />
        Co-founder of Metis
      </>
    ),
    desc: 'Elena involved in the incubation of LazAI and exploring its deep integration with Metis.',
    image: require('assets/img/Valeri.png'),
  },
  {
    id: 6,
    name: 'Nara',
    role: 'Product Lead',
    desc: 'Nara has participated in the design and development of multiple projects at Binance Lab. Overseeing the overall design and development of LanAI products.',
    image: require('assets/img/Nara.png'),
  },
  {
    id: 7,
    name: 'Daniel Kwak',
    role: (
      <>
        Marketing Lead
        <br />
        an expert in blockchain
        <br />
        marketing
      </>
    ),
    desc: 'Marketing Lead, 8 years in marketing & strategic leadership roles, consulting & advisory.',
    image: require('assets/img/Daniel_Kwak.png'),
  },
  {
    id: 8,
    name: 'Cookie',
    role: 'Operations Lead',
    desc: 'Operation Lead, 6 years in business strategy & operational leadership roles in the cryptocurrency industry.',
    image: require('assets/img/Cookie.png'),
  },
  {
    id: 9,
    name: 'Ben',
    role: 'Community',
    desc: 'an evangelist for LazAI, managing community building and engagement.',
    image: require('assets/img/Ben.png'),
  },
];

const OurTeam: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const prevRef = React.useRef<HTMLButtonElement>(null);
  const nextRef = React.useRef<HTMLButtonElement>(null);

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const lineVariants = {
    hidden: {
      width: '0%',
    },
    visible: {
      width: '56px',
      transition: {
        duration: 1,
        ease: 'easeInOut',
      },
    },
  };

  const bgVariants = {
    hidden: {
      opacity: 0,
      filter: 'blur(10px)',
    },
    visible: (i: number) => ({
      opacity: 1,
      filter: 'none',
      transition: {
        delay: i * 0.5,
        duration: 1,
        ease: 'easeIn',
      },
    }),
  };

  const variants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
      filter: 'blur(10px)',
    },
    visible: (i: number) => ({
      opacity: 1,
      scale: 1,
      filter: 'none',
      transition: {
        delay: i * 0.5,
        duration: 1,
        ease: 'easeIn',
      },
    }),
  };

  return isMobile ? (
    <MobileWrapper ref={ref}>
      <BackgroundImageBox
        variants={bgVariants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        custom={0}
      >
        <MobileBackgroundImage
          src={require('assets/img/team_background.png')}
        />
      </BackgroundImageBox>
      {inView ? (
        <TextAnimation text="Our Team" fontSize="36px" />
      ) : (
        <Title></Title>
      )}
      <Line
        variants={lineVariants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
      />
      <MobileSwiperWrapper
        variants={variants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        custom={1}
      >
        <Swiper
          slidesPerView={'auto'}
          centeredSlides={true}
          spaceBetween={70}
          pagination={false}
          modules={[Pagination, Navigation]}
          className="mySwiper"
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onSlideChange={(swiper: SwiperClass) =>
            setActiveIndex(swiper.activeIndex)
          }
        >
          {teamMembers.map((member, index) => (
            <SwiperSlide key={member.id}>
              <TeamCard member={member} isActive={index === activeIndex} />
            </SwiperSlide>
          ))}
        </Swiper>
      </MobileSwiperWrapper>
    </MobileWrapper>
  ) : (
    <TeamContainer ref={ref}>
      <BackgroundImageBox
        variants={bgVariants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        custom={0}
      >
        <BackgroundImage src={require('assets/img/team_background.png')} />
      </BackgroundImageBox>
      <LeftMask />
      <RightMask />
      {inView ? (
        <TextAnimation text="Our Team" fontSize="48px" />
      ) : (
        <Title></Title>
      )}
      <Line
        variants={lineVariants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
      />
      <SwiperWrapper
        variants={variants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        custom={1}
      >
        <Swiper
          slidesPerView={'auto'}
          centeredSlides={true}
          spaceBetween={100}
          pagination={false}
          modules={[Pagination, Navigation]}
          className="mySwiper"
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onSlideChange={(swiper: SwiperClass) =>
            setActiveIndex(swiper.activeIndex)
          }
        >
          {teamMembers.map((member, index) => (
            <SwiperSlide key={member.id}>
              <TeamCard member={member} isActive={index === activeIndex} />
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperWrapper>
      <LeftArrowButton
        variants={variants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        custom={2}
      >
        <ArrowButton ref={prevRef}>
          <ArrowBack />
        </ArrowButton>
      </LeftArrowButton>
      <RightArrowButton
        variants={variants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        custom={2}
      >
        <ArrowButton ref={nextRef}>
          <ArrowForward />
        </ArrowButton>
      </RightArrowButton>
    </TeamContainer>
  );
};

export default OurTeam;
