import React from 'react';
import styled from 'styled-components';
import { P1XSvg, MetisSvg } from 'assets/img';
import TextAnimation from 'components/TextAnimation';
import { useInView } from 'react-intersection-observer';
import { Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 140px;
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
  padding-top: 60px;
`;

const Title = styled(Typography)`
  color: #fff;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
`;

const CardsContainer = styled(motion.div)`
  display: flex;
  width: 80%;
  min-width: 1200px;
  max-width: 1400px;
  gap: 40px;
  margin-top: 32px;
`;

const MobileCardsContainer = styled(motion.div)`
  display: flex;
  width: 100%;
  gap: 20px;
  margin-top: 32px;
  padding: 0px 24px;
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 40px;
  width: 100%;
  aspect-ratio: 100 / 35;
  border-radius: 25.704px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    rgba(203, 255, 252, 0.08);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease;
  z-index: 100;
  cursor: pointer;
  /* &:hover {
    scale: 1.1;
  } */
`;

const MobileCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 40px;
  width: calc(50% - 10px);
  aspect-ratio: 100 / 90;
  border-radius: 25.704px;
  cursor: pointer;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    rgba(203, 255, 252, 0.08);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease;

  &:hover {
    scale: 1.1;
  }
`;

const Metis = styled(MetisSvg)`
  width: 186px;
  height: auto;
`;

const P1X = styled(P1XSvg)`
  width: 160px;
  height: auto;
`;

interface SectionProps {
  id?: string;
  className?: string;
}

const Backed: React.FC<SectionProps> = ({ id, className }) => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  const variants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
      filter: 'blur(2px)',
    },
    visible: (i: number) => ({
      opacity: 1,
      scale: 1,
      filter: 'none',
      transition: {
        delay: i * 1,
        duration: 1.5,
        ease: 'easeIn',
      },
    }),
  };

  const handleButtonClick = (type: string) => {
    if (type === 'metis') {
      window.open('https://metis.io', '_blank');
    } else {
      window.open('https://www.p1xlabs.com', '_blank');
    }
  };

  return isMobile ? (
    <MobileWrapper ref={ref} id={id} className={className}>
      {inView && <TextAnimation text="Incubated By" fontSize="36px" />}
      <MobileCardsContainer
        variants={variants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        custom={0}
      >
        <MobileCard
          onClick={() => {
            handleButtonClick('metis');
          }}
        >
          <Metis />
        </MobileCard>

        <MobileCard
          onClick={() => {
            handleButtonClick('p1x');
          }}
        >
          <P1X />
        </MobileCard>
      </MobileCardsContainer>
    </MobileWrapper>
  ) : (
    <Wrapper ref={ref} id={id} className={className}>
      {inView && <TextAnimation text="Incubated By" fontSize="48px" />}
      <CardsContainer
        variants={variants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        custom={0}
      >
        <Card
          onClick={() => {
            handleButtonClick('metis');
          }}
        >
          <Metis />
        </Card>

        <Card
          onClick={() => {
            handleButtonClick('p1x');
          }}
        >
          <P1X />
        </Card>
      </CardsContainer>
    </Wrapper>
  );
};

export default Backed;
