import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import { PointMaskSvg } from 'assets/img';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  aspect-ratio: 100 / 63;
  position: relative;
  z-index: 1;
  padding: 0 88px;
  background: url(${require('assets/img/point_bg.png')}) no-repeat center center;
  background-size: 100% 100%;
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  position: relative;
  z-index: 1;
  background: url(${require('assets/img/point_bg.png')}) no-repeat center center;
  background-size: cover;
`;

const PointMask = styled(PointMaskSvg)`
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const MobilePointMask = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 560px;
  position: relative;
  z-index: 999;
`;

const MobileContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 999;
  padding: 100px 28px;
`;

const Title = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 68px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
`;

const MobileTitle = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px;
  margin-top: 100px;
`;

const Subtitle = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  margin-top: 8px;
`;

const MobileSubtitle = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;
`;

const CountdownText = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  margin-top: 34px;
  margin-bottom: 12px;
`;

const CountdownTips = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  margin-top: 34px;
  margin-bottom: 12px;
`;

const MobileCountdownTips = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  margin-top: 34px;
  margin-bottom: 12px;
`;

const CountdownWrapper = styled(motion.div)`
  display: flex;
  gap: 18px;
  margin-bottom: 10vh;
`;

const MobileCountdownWrapper = styled(motion.div)`
  display: flex;
  gap: 18px;
  width: 100%;
`;

const CountdownItem = styled.div`
  display: flex;
  width: 140px;
  height: 118px;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(6px);
`;

const MobileCountdownItem = styled.div`
  display: flex;
  flex: 1;
  height: 97px;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(6px);
`;

const CountdownTime = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: Akshar;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
`;

const MobileCountdownTime = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: Akshar;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
`;

const CountdownName = styled(Typography)`
  color: #fff;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const MobileCountdownName = styled(Typography)`
  color: #fff;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const CountdownIcon = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: Syne;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 118px;
`;

const MobileCountdownIcon = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: Syne;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 97px;
`;

const FeaturesWrapper = styled(motion.div)`
  display: flex;
  gap: 32px;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 999;
  width: 90%;
  position: absolute;
  bottom: 20px;
`;

const MobileFeaturesWrapper = styled(motion.div)`
  display: flex;
  gap: 32px;
  flex-direction: row;
  z-index: 999;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 40px;
  padding: 0 28px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const FeatureCard = styled.div`
  gap: 8px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const MobileFeatureCard = styled.div`
  gap: 8px;
  display: flex;
  width: 70vw;
  min-width: 240px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const CardLine = styled.div`
  width: 28px;
  height: 2px;
  background: var(
    --red-gradient,
    linear-gradient(90deg, #ff229f 0%, #ff4570 53.5%, #ff7300 100%)
  );
`;

const CardTitle = styled(Typography)`
  color: #fff;
  font-family: Syne;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const CardDesc = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const MiddleLine = styled.div`
  width: 1px;
  height: 58px;
  flex-shrink: 0;
  border-radius: 14px;
  opacity: 0.5;
  background: #fff;
  margin-top: 38px;
`;

const AnimationBox = styled(motion.div)`
  width: 100%;
`;

const PointDetail: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const variants = {
    hidden: {
      opacity: 0,
      y: 150,
    },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.4,
        duration: 0.8,
      },
    }),
  };

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [hasEnded, setHasEnded] = useState(false);

  useEffect(() => {
    const deadline = dayjs('2025-01-15T00:00:00Z');

    const calculateTimeLeft = () => {
      const now = dayjs();
      const difference = deadline.diff(now);

      if (difference <= 0) {
        return { days: 0, hours: 0, minutes: 0, seconds: 0 };
      }

      return {
        days: deadline.diff(now, 'days'),
        hours: deadline.diff(now, 'hours') % 24,
        minutes: deadline.diff(now, 'minutes') % 60,
        seconds: deadline.diff(now, 'seconds') % 60,
      };
    };

    const updateCountdown = () => {
      const remainingTime = calculateTimeLeft();
      setTimeLeft(remainingTime);

      if (
        remainingTime.days === 0 &&
        remainingTime.hours === 0 &&
        remainingTime.minutes === 0 &&
        remainingTime.seconds === 0
      ) {
        setHasEnded(true);
        clearInterval(timer);
      }
    };

    const timer = setInterval(updateCountdown, 60000);

    updateCountdown();

    return () => clearInterval(timer);
  }, []);

  return isMobile ? (
    <MobileWrapper>
      <video
        ref={videoRef}
        src={'/video/loyalty_point_header.mp4'}
        autoPlay={true}
        loop={false}
        muted
        playsInline
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
      <MobilePointMask src={require('assets/img/mobile_point_mask.png')} />
      <MobileContentBox>
        <AnimationBox
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={0}
        >
          <MobileTitle>
            LazAI
            <br />
            Loyalty Point
            <br />
            Program
          </MobileTitle>
        </AnimationBox>
        <AnimationBox
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={1}
        >
          <MobileSubtitle>
            This program is to reward all early participants of LazAI, providing
            fair and just incentives to all users.
          </MobileSubtitle>
        </AnimationBox>
        <AnimationBox
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={2}
        >
          {/* <CountdownText>
            {hasEnded ? 'Phase 1 Ended' : 'Phase 1 Ends in'}
          </CountdownText> */}
          <MobileCountdownTips>Coming Soon</MobileCountdownTips>
        </AnimationBox>
        {/* <MobileCountdownWrapper
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={3}
        >
          <MobileCountdownItem>
            <MobileCountdownTime>{timeLeft.days}</MobileCountdownTime>
            <MobileCountdownName>Days</MobileCountdownName>
          </MobileCountdownItem>
          <MobileCountdownIcon>:</MobileCountdownIcon>
          <MobileCountdownItem>
            <MobileCountdownTime>{timeLeft.hours}</MobileCountdownTime>
            <MobileCountdownName>Hours</MobileCountdownName>
          </MobileCountdownItem>
          <MobileCountdownIcon>:</MobileCountdownIcon>
          <MobileCountdownItem>
            <MobileCountdownTime>{timeLeft.minutes}</MobileCountdownTime>
            <MobileCountdownName>Mins</MobileCountdownName>
          </MobileCountdownItem>
        </MobileCountdownWrapper> */}
      </MobileContentBox>

      <MobileFeaturesWrapper
        variants={variants}
        initial="hidden"
        animate="visible"
        custom={4}
      >
        <MobileFeatureCard>
          <CardTitle>Participant Engagement</CardTitle>
          <CardLine />
          <CardDesc>
            Applied to every aspect of your interactions within LazAI
          </CardDesc>
        </MobileFeatureCard>
        <MiddleLine />
        <MobileFeatureCard>
          <CardTitle>Community Governance</CardTitle>
          <CardLine />
          <CardDesc>iDAO will be governance based on ownership points</CardDesc>
        </MobileFeatureCard>
        <MiddleLine />
        <MobileFeatureCard>
          <CardTitle>Priority And Node Rights</CardTitle>
          <CardLine />
          <CardDesc>
            New features and content, first made available to engaged
            participants
          </CardDesc>
        </MobileFeatureCard>
        <MiddleLine />
        <MobileFeatureCard>
          <CardTitle>Incentive</CardTitle>
          <CardLine />
          <CardDesc>Airdrop for contributors deeply involved in LazAI</CardDesc>
        </MobileFeatureCard>
      </MobileFeaturesWrapper>
    </MobileWrapper>
  ) : (
    <Wrapper>
      <video
        ref={videoRef}
        src={'/video/loyalty_point_header.mp4'}
        autoPlay={true}
        loop={false}
        muted
        playsInline
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
      <PointMask />
      <ContentBox>
        <AnimationBox
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={0}
        >
          <Title>
            LazAI
            <br />
            Loyalty Point
            <br />
            Program
          </Title>
        </AnimationBox>
        <AnimationBox
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={1}
        >
          <Subtitle>
            This program is to reward all early participants of LazAI, providing
            fair and just incentives to all users.
          </Subtitle>
        </AnimationBox>

        <AnimationBox
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={2}
        >
          {/* <CountdownText>
            {hasEnded ? 'Phase 1 Ended' : 'Phase 1 Ends in'}
          </CountdownText> */}
          <CountdownTips>Coming Soon</CountdownTips>
        </AnimationBox>

        {/* <CountdownWrapper
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={3}
        >
          <CountdownItem>
            <CountdownTime>{timeLeft.days}</CountdownTime>
            <CountdownName>Days</CountdownName>
          </CountdownItem>
          <CountdownIcon>:</CountdownIcon>
          <CountdownItem>
            <CountdownTime>{timeLeft.hours}</CountdownTime>
            <CountdownName>Hours</CountdownName>
          </CountdownItem>
          <CountdownIcon>:</CountdownIcon>
          <CountdownItem>
            <CountdownTime>{timeLeft.minutes}</CountdownTime>
            <CountdownName>Mins</CountdownName>
          </CountdownItem>
        </CountdownWrapper> */}
      </ContentBox>

      <FeaturesWrapper
        variants={variants}
        initial="hidden"
        animate="visible"
        custom={4}
      >
        <FeatureCard>
          <CardTitle>Participant Engagement</CardTitle>
          <CardLine />
          <CardDesc>
            Applied to every aspect of your interactions within LazAI
          </CardDesc>
        </FeatureCard>
        <MiddleLine />
        <FeatureCard>
          <CardTitle>Community Governance</CardTitle>
          <CardLine />
          <CardDesc>iDAO will be governance based on ownership points</CardDesc>
        </FeatureCard>
        <MiddleLine />
        <FeatureCard>
          <CardTitle>Priority And Node Rights</CardTitle>
          <CardLine />
          <CardDesc>
            New features and content, first made available to engaged
            participants
          </CardDesc>
        </FeatureCard>
        <MiddleLine />
        <FeatureCard>
          <CardTitle>Incentive</CardTitle>
          <CardLine />
          <CardDesc>Airdrop for contributors deeply involved in LazAI</CardDesc>
        </FeatureCard>
      </FeaturesWrapper>
    </Wrapper>
  );
};

export default PointDetail;
