import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';

const CardContainer = styled.div`
  perspective: 1000px;
  width: 200px;
  height: 200px;
  position: relative;

  .swiper-slide-active & {
    height: 240px;
    cursor: pointer;
  }
`;

const MobileCardContainer = styled.div`
  perspective: 1000px;
  width: 30vw;
  height: 30vw;
  position: relative;

  .swiper-slide-active & {
    height: 38vw;
    cursor: pointer;
  }
`;

const Content = styled.div<{ flipped: boolean }>`
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  transform: ${(props) =>
    props.flipped ? 'rotateY(180deg)' : 'rotateY(0deg)'};
`;

const CardMask = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: rgba(1, 12, 94, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  z-index: 99999;

  .swiper-slide-active & {
    opacity: 0;
  }
`;

const Front = styled.div`
  background-color: #020735;
  color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: auto;

  border-radius: 100px 0px;
  .swiper-slide-active & {
    border: 8px solid #04f;
  }
`;

const MobileFront = styled.div`
  background-color: #020735;
  color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: auto;

  border-radius: 50% 0px;
  .swiper-slide-active & {
    border: 4px solid #04f;
  }
`;

const Back = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  border-radius: 0px 100px;
  .swiper-slide-active & {
    border: 8px solid #04f;
  }
`;

const MobileBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  border-radius: 0px 50%;
  .swiper-slide-active & {
    border: 4px solid #04f;
  }
`;

const CirclesBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1 !important;
  overflow: visible;
`;

const Circle = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #ffbb66;
  position: absolute;
  filter: blur(15px);
  animation: floating 9s infinite ease-in-out;
  z-index: 1 !important;

  @keyframes floating {
    0% {
      transform: translate(0px, 0px);
    }
    25% {
      transform: translate(20px, -10px);
    }
    50% {
      transform: translate(-15px, 15px);
    }
    75% {
      transform: translate(10px, -20px);
    }
    100% {
      transform: translate(0px, 0px);
    }
  }
`;

const ProfileImage = styled.img`
  width: 200px;
  height: auto;
  position: relative;
`;

const MobileProfileImage = styled.img`
  width: 30vw;
  height: auto;
  position: relative;
`;

const Mask = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.65) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;

  .swiper-slide-active & {
    opacity: 1;
  }
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  right: 5px;
  bottom: 5px;
  opacity: 0;

  .swiper-slide-active & {
    opacity: 1;
  }
`;

const Name = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
`;

const MobileName = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
`;

const Role = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  text-align: right;
`;

const MobileRole = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 6px;
  font-style: normal;
  font-weight: 400;
  line-height: 8px;
  text-align: right;
`;

const DescBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: visible;
  gap: 6px;
  padding: 0 10px;
`;

const MobileDescBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 3px;
  padding: 0 10px;
  margin-top: 10px;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const MobileAvatar = styled.img`
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
`;

const Desc = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  border-radius: 100px 0px;

  position: relative;
  z-index: 99999;
`;

const MobileDesc = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  border-radius: 100px 0px;

  position: relative;
  z-index: 99999;
`;

interface TeamMember {
  id: number;
  name: string;
  role: any;
  desc: string;
  image: any;
}

interface CardProps {
  isActive: boolean;
  member: TeamMember;
}

const TeamCard: React.FC<CardProps> = ({ member, isActive }) => {
  const [flipped, setFlipped] = useState(false);

  useEffect(() => {
    if (!isActive) {
      setFlipped(false);
    }
  }, [isActive]);

  const handleFlip = () => {
    if (isActive) {
      setFlipped((prev) => !prev);
    }
  };

  return isMobile ? (
    <MobileCardContainer onClick={handleFlip}>
      <Content flipped={flipped}>
        <MobileFront>
          <MobileDescBox>
            <MobileDesc>{member.desc}</MobileDesc>
          </MobileDescBox>
        </MobileFront>
        <MobileBack>
          <MobileProfileImage src={member.image} />
          <CardMask />
          <Mask />
          <TextBox>
            <MobileName>{member.name}</MobileName>
            <MobileRole>{member.role}</MobileRole>
          </TextBox>
        </MobileBack>
      </Content>
    </MobileCardContainer>
  ) : (
    <CardContainer onClick={handleFlip}>
      <Content flipped={flipped}>
        <Front>
          {/* <CirclesBox>
            <Circle style={{ top: '20px', left: '20px' }} />
            <Circle
              style={{
                top: '105px',
                left: '10px',
                width: '120px',
                height: '120px',
                backgroundColor: '#ff8866',
                animationDelay: '-800ms',
              }}
            />
            <Circle
              style={{
                top: '50px',
                left: '110px',
                width: '50px',
                height: '50px',
                backgroundColor: '#ff2233',
                animationDelay: '-1800ms',
              }}
            />
          </CirclesBox> */}
          <DescBox>
            <Desc>{member.desc}</Desc>
          </DescBox>
        </Front>
        <Back>
          <ProfileImage src={member.image} />
          <CardMask />
          <Mask />
          <TextBox>
            <Name>{member.name}</Name>
            <Role>{member.role}</Role>
          </TextBox>
        </Back>
      </Content>
    </CardContainer>
  );
};

export default TeamCard;
