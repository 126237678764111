import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { isMobile } from 'react-device-detect';
import { Typography } from '@mui/material';
import HoverButton from 'components/HoverButton';
import { useNavigate } from 'react-router-dom';
import PointDetail from './components/PointDetail';
import HowToParticipate from './components/HowToParticipate';
import DiscoverIDAO from './components/DiscoverIDAO';
import { throttle } from 'lodash';
import api from 'request/api';
import { TechLink, DocsLink } from 'constant';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;

  height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  overflow-x: hidden;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
`;

const Nav = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  transform: translate(50px, -50px);
  transition: transform 0.5s, opacity 0.5s;
  z-index: 99999;
  background: #010633;
  padding: 90px 24px 60px 24px;
  gap: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  &.open {
    pointer-events: auto;
    opacity: 1;
    transform: translate(0, 0);
  }
`;

const NavItem = styled(Typography)`
  display: block;
  color: var(--White, #fff);
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  transform: scaleY(0.5);
  transition: transform 0.5s, opacity 0.5s;
  opacity: 0;

  &.visible {
    transform: scaleY(1);
    opacity: 1;
  }
`;

export interface SectionOffsets {
  [key: string]: number;
}

export default function LoyaltyPoint() {
  const containerRef = useRef<HTMLDivElement>(null);
  const participateRef = useRef<HTMLDivElement>(null);
  const discoverRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const handleNavigate = (sectionId: string) => {
    if (sectionId === 'Participate' && participateRef.current) {
      participateRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (sectionId === 'Discover' && discoverRef.current) {
      discoverRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (sectionId === 'FAQ') {
      navigate('/faq');
    }
  };

  const [scrollTop, setScrollTop] = useState(0);
  const [open, setOpen] = useState(false);
  const [totalData, setTotalData] = useState<any>(null);

  useEffect(() => {
    const getPoint = async () => {
      const response = await api.get('/allIDAO?page=1&pageNum=50');
      console.log(response);
      if (response.code === 200) {
        setTotalData(response.data);
      }
    };

    const getIDao = async () => {
      const response = await api.get('/iDAO?page=1&pageNum=50');
      console.log(response);
      if (response.code === 200) {
        // setTotalData(response.data);
      }
    };

    getPoint();
    getIDao();

    const container = containerRef.current;

    const handleScroll = throttle(() => {
      if (container) {
        setScrollTop(container.scrollTop);
      }
    }, 100);

    container?.addEventListener('scroll', handleScroll);

    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onMenuClick = () => {
    setOpen(!open);
  };

  const handleNavigateItem = (sectionId: string) => {
    setOpen(false);
    if (sectionId === 'Participate' && participateRef.current) {
      participateRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (sectionId === 'Discover' && discoverRef.current) {
      discoverRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (sectionId === 'Home' && discoverRef.current) {
      navigate('/landingPage');
    } else if (sectionId === 'Tech Lightpaper') {
      window.open(TechLink, '_blank');
    } else if (sectionId === 'Docs') {
      window.open(DocsLink, '_blank');
    } else if (sectionId === 'FAQ') {
      navigate('/faq');
    }
  };

  const goHomePage = () => {
    navigate('/landingPage');
  };

  const goDiscoverItem = () => {
    if (discoverRef.current) {
      discoverRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Container ref={containerRef}>
      <Header
        scrollTop={scrollTop}
        open={open}
        page="loyaltyPoint"
        onNavigate={handleNavigate}
        onMenuClick={onMenuClick}
        goHomePage={goHomePage}
      />
      {isMobile && (
        <Nav className={open ? 'open' : ''}>
          {[
            'Home',
            'Participate',
            'Discover',
            'Tech Lightpaper',
            'Docs',
            'FAQ',
          ].map((item, index) => (
            <NavItem
              key={item}
              className={open ? 'visible' : ''}
              style={{ transitionDelay: `${index * 0.1}s` }}
              onClick={() => handleNavigateItem(item)}
            >
              {item}
            </NavItem>
          ))}
        </Nav>
      )}
      <PointDetail />
      <div style={{ width: '100vw' }} ref={participateRef}>
        <HowToParticipate handleJoinButton={goDiscoverItem} />
      </div>
      <div style={{ width: '100vw' }} ref={discoverRef}>
        <DiscoverIDAO totalData={totalData} />
      </div>
      <Footer />
    </Container>
  );
}
