import React from 'react';
import styled from 'styled-components';
import HoverButton from 'components/HoverButton';
import TextAnimation from 'components/TextAnimation';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import { useInView } from 'react-intersection-observer';
import { Button } from '@mui/material';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  aspect-ratio: 100 / 52;
  position: relative;
  z-index: 1;
  margin-bottom: 160px;
`;

const MobileWrapper = styled.div`
  display: flex;
  height: 100vh;
  padding: 100px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  align-self: stretch;
  z-index: 1;
  position: relative;
`;

const BgBox = styled.div`
  display: flex;
  width: 100%;
  aspect-ratio: 100 / 56;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const MobileBgBox = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const GradientButton = styled(Button)`
  position: relative;
  border: 2px solid white;
  padding: 20px 28px;
  cursor: pointer;
  color: white;
  font-weight: 700;
  font-size: 20px;
  font-family: 'Syne', sans-serif;
  text-transform: none;
  width: 310px;
  height: 62px;
  border-radius: 31px;
  overflow: hidden;

  &:hover {
    border-color: transparent;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-image: linear-gradient(90deg, #ff229f, #ff7300);
    transition: height 0.3s ease;
    z-index: -1;
  }

  &:hover::after {
    height: 100%;
  }
`;

interface SectionProps {
  id?: string;
  className?: string;
}

const TopModules: React.FC<SectionProps> = ({ id, className }) => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  const variants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
      filter: 'blur(2px)',
    },
    visible: (i: number) => ({
      opacity: 1,
      scale: 1,
      filter: 'none',
      transition: {
        delay: i * 1,
        duration: 1,
        ease: 'easeIn',
      },
    }),
  };

  const handleButtonClick = () => {
    window.open('https://t.me/lazai_global', '_blank');
  };

  return isMobile ? (
    <MobileWrapper ref={ref} id={id} className={className}>
      <MobileBgBox>
        <video
          src={'/video/header.mp4'}
          autoPlay
          loop
          muted
          playsInline
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
          }}
        />
      </MobileBgBox>
      <TextAnimation
        text={`An open\nnetwork for the\ncomposable\nand open\nAI environment`}
        fontSize="32px"
      />
      <motion.div
        variants={variants}
        initial="hidden"
        animate="visible"
        custom={2.8}
        style={{
          zIndex: 2,
        }}
      >
        <HoverButton
          text="Join LazAI Community"
          fontSize="20px"
          width="80vw"
          height="62px"
          borderRadius="31px"
          onClick={handleButtonClick}
        />
      </motion.div>
    </MobileWrapper>
  ) : (
    <Wrapper id={id} className={className}>
      <BgBox>
        <video
          src={'/video/header.mp4'}
          autoPlay
          loop
          muted
          playsInline
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </BgBox>
      <TextAnimation
        text={`An open network for the\ncomposable and open\nAI environment`}
      />
      <motion.div
        variants={variants}
        initial="hidden"
        animate="visible"
        custom={1.5}
        style={{
          zIndex: 2,
        }}
      >
        <GradientButton variant="outlined" onClick={handleButtonClick}>
          Join LazAI Community
        </GradientButton>
      </motion.div>
    </Wrapper>
  );
};

export default TopModules;
