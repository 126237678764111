import React from 'react';
import styled from 'styled-components';
import { Typography, Button } from '@mui/material';
import {
  ParticipateCardImage1Svg,
  ParticipateCardImage2Svg,
  ParticipateCardImage3Svg,
  LazAIBgSvg,
} from 'assets/img';
import HoverButton from 'components/HoverButton';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import Spline from '@splinetool/react-spline';
import { useInView } from 'react-intersection-observer';
import TextAnimation from 'components/TextAnimation';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: linear-gradient(
    183deg,
    #0051ff 2.62%,
    #0051ff 50%,
    rgba(0, 81, 255, 0) 85%
  );
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 160px 0 100px 0;
  gap: 80px;
  background: linear-gradient(
    183deg,
    #0051ff 2.62%,
    #0051ff 50%,
    rgba(0, 81, 255, 0) 85%
  );
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  min-width: 1200px;
  max-width: 1400px;
  padding: 150px 100px 100px 100px;
  gap: 75px;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: Syne;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  height: 121.81px;
`;

const MobileTitle = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: Syne;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
`;

const Line = styled(motion.div)`
  height: 4px;
  width: 56px;
  background: var(
    --red-gradient,
    linear-gradient(90deg, #ff229f 0%, #ff4570 53.5%, #ff7300 100%)
  );
`;

const StepsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  position: relative;
`;

const MobileStepsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  height: 432px;
  padding: 0 28px;
  position: relative;
`;

const Step = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 200px;
  position: absolute;
  z-index: 9999;

  span {
    color: #fff;
    font-family: Syne;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;
    width: 100%;
  }

  p {
    color: #fff;
    font-family: Syne;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    margin-top: 10px;
  }
`;

const StepLine = styled.div`
  height: 0.5px;
  width: 100%;
  align-self: stretch;
  background: #fff;
`;

const CardGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`;

const MobileCardGrid = styled(motion.div)`
  position: relative;
  width: 100vw;
`;

const Card = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 340px;
  padding: 44px 24px 24px 30px;
  position: relative;
  overflow: hidden;
  gap: 20px;
  background: url(${require('assets/img/participate_card_bg.png')}) no-repeat
    center center;
  background-size: 100% 100%;
`;

const MobileCardBox = styled.div`
  display: flex;
  padding: 0 28px;
`;

const MobileCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 300px;
  padding: 40px 24px 24px 30px;
  position: relative;
  overflow: hidden;
  gap: 20px;
  background: url(${require('assets/img/participate_card_bg.png')}) no-repeat
    center center;
  background-size: 100% 100%;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 20px;
`;

const ParticipateCardImage1 = styled(ParticipateCardImage1Svg)`
  width: 100%;
  height: auto;
`;

const ParticipateCardImage2 = styled(ParticipateCardImage2Svg)`
  width: 100%;
  height: auto;
`;

const ParticipateCardImage3 = styled(ParticipateCardImage3Svg)`
  width: 100%;
  height: auto;
`;

const LazAIBg = styled(LazAIBgSvg)`
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 1;
`;

const CardTitle = styled(Typography)`
  color: #fff;
  font-family: Syne;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const CardDesc = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const CardButton = styled.div`
  display: flex;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 39px;
  background: rgba(255, 255, 255, 0.2);
  height: 44px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  font-family: 'Syne', sans-serif;
  text-transform: none;
  cursor: pointer;
`;

const SplineBox = styled(motion.div)`
  width: 400px;
  height: 400px;
  z-index: 999;
`;

interface ParticipateProps {
  handleJoinButton: () => void;
}

const HowToParticipate: React.FC<ParticipateProps> = ({ handleJoinButton }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const lineVariants = {
    hidden: {
      width: '0%',
    },
    visible: {
      width: '56px',
      transition: {
        duration: 1,
        ease: 'easeInOut',
      },
    },
  };

  const imageVariants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
      filter: 'blur(10px)',
    },
    visible: (i: number) => ({
      opacity: 1,
      scale: 1,
      filter: 'none',
      transition: {
        delay: i * 0.5,
        duration: 1,
        ease: 'easeIn',
      },
    }),
  };

  const variants = {
    hidden: {
      opacity: 0,
      y: 150,
    },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.4,
        duration: 0.8,
      },
    }),
  };

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    cssEase: 'linear',
  };

  return isMobile ? (
    <MobileWrapper ref={ref}>
      <TitleBox>
        {inView ? (
          <TextAnimation text="How To Participate" fontSize="32px" />
        ) : (
          <Title></Title>
        )}
        <Line
          variants={lineVariants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
        />
      </TitleBox>

      <MobileStepsWrapper>
        <LazAIBg />
        <Step
          style={{
            top: 0,
            left: 28,
          }}
          variants={variants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          custom={1}
        >
          <span>Step 1</span>
          <StepLine />
          <p>
            Choose <strong>Your Role</strong> as a contributor
          </p>
        </Step>
        <SplineBox
          variants={imageVariants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          custom={0}
        >
          <Spline scene="https://prod.spline.design/D-luLHnHDAIzk04a/scene.splinecode" />
        </SplineBox>
        <Step
          style={{
            bottom: 0,
            right: 28,
            textAlign: 'right',
          }}
          variants={variants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          custom={2}
        >
          <span>Step 2</span>
          <StepLine />
          <p>
            Contribute and participate in IDAO to earn{' '}
            <strong>LazAI Loyalty Points</strong> as incentives
          </p>
        </Step>
      </MobileStepsWrapper>

      <MobileCardGrid
        variants={variants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        custom={3}
      >
        <Slider {...settings}>
          <MobileCardBox>
            <MobileCard>
              <CardContent>
                <ParticipateCardImage1 />
                <CardTitle>IDAO Contributor</CardTitle>
                <CardDesc>
                  The valuable dataset should come from everyone. So become a
                  dataset & vertical model contributor to establish the
                  footstone of the future AI.
                </CardDesc>
              </CardContent>
              <HoverButton
                text="Join iDAO"
                width="143px"
                height="36px"
                borderRadius="18px"
                fontSize="16px"
                onClick={handleJoinButton}
              />
            </MobileCard>
          </MobileCardBox>

          <MobileCardBox>
            <MobileCard>
              <CardContent>
                <ParticipateCardImage2 />
                <CardTitle>AI Agent Contributor</CardTitle>
                <CardDesc>
                  The valuable dataset should come from everyone. So become a
                  dataset & vertical model contributor to establish the
                  footstone of the future AI.
                </CardDesc>
              </CardContent>
              <CardButton>Available in Phase 2</CardButton>
            </MobileCard>
          </MobileCardBox>

          <MobileCardBox>
            <MobileCard>
              <CardContent>
                <ParticipateCardImage3 />
                <CardTitle>IDAO Creator</CardTitle>
                <CardDesc>
                  Create your own AI Agents through LazAI and expand your IDAO
                  with Agents to maximize benefits from your thoughts.
                </CardDesc>
              </CardContent>
              <CardButton>Available in Phase 2</CardButton>
            </MobileCard>
          </MobileCardBox>
        </Slider>
      </MobileCardGrid>
    </MobileWrapper>
  ) : (
    <Wrapper ref={ref}>
      <Container>
        <TitleBox>
          {inView ? (
            <TextAnimation text="How To Participate" fontSize="48px" />
          ) : (
            <Title></Title>
          )}
          <Line
            variants={lineVariants}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
          />
        </TitleBox>

        <StepsWrapper>
          <LazAIBg />
          <Step
            style={{
              top: 60,
              left: 24,
            }}
            variants={variants}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            custom={1}
          >
            <span>Step 1</span>
            <StepLine />
            <p>
              Choose <strong>Your Role</strong> as a contributor
            </p>
          </Step>
          <SplineBox
            variants={imageVariants}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            custom={0}
          >
            <Spline scene="https://prod.spline.design/D-luLHnHDAIzk04a/scene.splinecode" />
          </SplineBox>
          <Step
            style={{
              bottom: 40,
              right: 24,
            }}
            variants={variants}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            custom={2}
          >
            <span>Step 2</span>
            <StepLine />
            <p>
              Contribute and participate in IDAO to earn{' '}
              <strong>LazAI Loyalty Points</strong> as incentives
            </p>
          </Step>
        </StepsWrapper>

        <CardGrid>
          <Card
            variants={variants}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            custom={3}
          >
            <CardContent>
              <ParticipateCardImage1 />
              <CardTitle>IDAO Contributor</CardTitle>
              <CardDesc>
                The valuable dataset should come from everyone. So become a
                dataset & vertical model contributor to establish the footstone
                of the future AI.
              </CardDesc>
            </CardContent>
            <HoverButton
              text="Join iDAO"
              width="143px"
              height="36px"
              borderRadius="18px"
              fontSize="16px"
              onClick={handleJoinButton}
            />
          </Card>

          <Card
            variants={variants}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            custom={4}
          >
            <CardContent>
              <ParticipateCardImage2 />
              <CardTitle>AI Agent Contributor</CardTitle>
              <CardDesc>
                The valuable dataset should come from everyone. So become a
                dataset & vertical model contributor to establish the footstone
                of the future AI.
              </CardDesc>
            </CardContent>
            <CardButton>Available in Phase 2</CardButton>
          </Card>

          <Card
            variants={variants}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            custom={5}
          >
            <CardContent>
              <ParticipateCardImage3 />
              <CardTitle>IDAO Creator</CardTitle>
              <CardDesc>
                Create your own AI Agents through LazAI and expand your IDAO
                with Agents to maximize benefits from your thoughts.
              </CardDesc>
            </CardContent>
            <CardButton>Available in Phase 2</CardButton>
          </Card>
        </CardGrid>
      </Container>
    </Wrapper>
  );
};

export default HowToParticipate;
