import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import Header from 'components/Header';
import Footer from 'components/Footer';
import TopModules from './components/TopModules';
import CardBox from './components/CardBox';
import Concept from './components/Concept';
import Network from './components/Network';
import Mechanism from './components/Mechanism';
import Backed from './components/Backed';
import BottomModules from './components/BottomModules';
import OurTeam from './components/OurTeam';
import './index.css';
import ReactFullpage, { Item } from '@fullpage/react-fullpage';
import MechanismCard from 'components/MechanismCard';
import { isMobile } from 'react-device-detect';
import { Typography } from '@mui/material';
import HoverButton from 'components/HoverButton';
import { useNavigate } from 'react-router-dom';
import { RightMaskSvg } from 'assets/img';
import { TechLink, DocsLink } from 'constant';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;

const Wrapper = styled.div`
  position: relative;
`;

const ContentBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  overflow: hidden;
`;

const MechanismContentBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  position: relative;
`;

const MobileMechanismContentBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  padding: 60px 24px 60px 24px;
`;

const Nav = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  transform: translate(50px, -50px);
  transition: transform 0.5s, opacity 0.5s;
  z-index: 5;
  background: #010633;
  padding: 90px 24px 60px 24px;
  gap: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  &.open {
    pointer-events: auto;
    opacity: 1;
    transform: translate(0, 0);
  }
`;

const NavItem = styled(Typography)`
  display: block;
  color: var(--White, #fff);
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  transform: scaleY(0.5);
  transition: transform 0.5s, opacity 0.5s;
  opacity: 0;

  &.visible {
    transform: scaleY(1);
    opacity: 1;
  }
`;

const RightMask = styled(RightMaskSvg)`
  width: 600px;
  height: auto;
  position: absolute;
  z-index: 1;
  right: 0;
`;

export interface SectionOffsets {
  [key: string]: number;
}

export default function LandingPage() {
  const networkRef = useRef<HTMLDivElement>(null);
  const mechanismRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const handleNavigate = (sectionId: string) => {
    if (sectionId === 'Network' && networkRef.current && fullpageApi) {
      fullpageApi.moveTo(1);
      networkRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (
      sectionId === 'Mechanism' &&
      mechanismRef.current &&
      fullpageApi
    ) {
      fullpageApi.moveTo(1);
      mechanismRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (sectionId === 'FAQ') {
      navigate('/faq');
    }
  };

  const firstData = contentData[0];
  const lastData = contentData[contentData.length - 1];

  const [scrollTop, setScrollTop] = useState(0);
  const [pageDestination, setPageDestination] = useState<Item>({
    index: 0,
  } as Item);
  const [open, setOpen] = useState(false);
  const [fullpageApi, setFullpageApi] = useState<any>(null);

  const onMenuClick = () => {
    setOpen(!open);
  };

  const handleNavigateItem = (sectionId: string) => {
    setOpen(false);
    if (sectionId === 'LazAI Network' && networkRef.current) {
      fullpageApi.moveTo(1);
      networkRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (sectionId === 'Solution' && mechanismRef.current) {
      fullpageApi.moveTo(1);
      mechanismRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (sectionId === 'Tech Lightpaper') {
      window.open(TechLink, '_blank');
    } else if (sectionId === 'Docs') {
      window.open(DocsLink, '_blank');
    } else if (sectionId === 'FAQ') {
      navigate('/faq');
    }
  };

  const goPointPage = () => {
    navigate('/loyaltyPoint');
  };

  const afterRender = () => {
    console.log('页面渲染完成！你可以在这里执行一些操作。');
  };

  useEffect(() => {
    if (fullpageApi) {
      fullpageApi.silentMoveTo(1);
    }
  }, [fullpageApi]);

  return (
    <Container>
      <Header
        scrollTop={scrollTop}
        open={open}
        onNavigate={handleNavigate}
        onMenuClick={onMenuClick}
        goPointPage={goPointPage}
      />
      {isMobile && (
        <Nav className={open ? 'open' : ''}>
          {['LazAI Network', 'Solution', 'Tech Lightpaper', 'Docs', 'FAQ'].map(
            (item, index) => (
              <NavItem
                key={item}
                className={open ? 'visible' : ''}
                style={{ transitionDelay: `${index * 0.1}s` }}
                onClick={() => handleNavigateItem(item)}
              >
                {item}
              </NavItem>
            ),
          )}
          {/* <HoverButton
            text={'Get started'}
            width="75vw"
            height="44px"
            fontSize="16px"
            borderRadius="22px"
            onClick={goPointPage}
          /> */}
        </Nav>
      )}
      <ReactFullpage
        licenseKey={'W7MG8-9T27I-KXQ76-4J9E7-NZHXO'}
        scrollBar={false}
        credits={{
          enabled: false,
          label: 'LazAI',
          position: 'right',
        }}
        onScrollOverflow={(section, slide, position) => {
          setScrollTop(position);
        }}
        beforeLeave={(origin, destination, direction) => {
          setPageDestination(destination);
        }}
        afterRender={afterRender}
        render={({ fullpageApi }) => {
          setFullpageApi(fullpageApi);
          return isMobile ? (
            <ReactFullpage.Wrapper>
              <Wrapper className="section">
                <ContentBox>
                  <TopModules id="top-modules" className="item" />
                  <div style={{ width: '100vw' }} id="network" ref={networkRef}>
                    <Network id="network" className="item" />
                  </div>
                  <div
                    style={{ width: '100vw' }}
                    id="mechanism"
                    ref={mechanismRef}
                  >
                    <Mechanism id="mechanism" className="item" />
                  </div>
                  <MobileMechanismContentBox>
                    <MechanismCard
                      url={firstData.url}
                      title={firstData.title}
                      desc={firstData.desc}
                      content={firstData.content}
                      isAction={pageDestination?.index !== 0}
                    />
                  </MobileMechanismContentBox>
                </ContentBox>
              </Wrapper>

              {contentData.slice(1, 3).map((item, index) => (
                <Wrapper className="section" key={`section ${index + 2}`}>
                  <MobileMechanismContentBox>
                    <MechanismCard
                      url={item.url}
                      title={item.title}
                      desc={item.desc}
                      content={item.content}
                      isAction={pageDestination?.index !== index + 1}
                    />
                  </MobileMechanismContentBox>
                </Wrapper>
              ))}

              <Wrapper className="section">
                <ContentBox>
                  <MobileMechanismContentBox>
                    <MechanismCard
                      url={lastData.url}
                      title={lastData.title}
                      desc={lastData.desc}
                      content={lastData.content}
                      isAction={pageDestination?.index !== 3}
                    />
                  </MobileMechanismContentBox>
                  <OurTeam />
                  <Backed id="backed" className="item" />
                  <BottomModules />
                  <Footer />
                </ContentBox>
              </Wrapper>
            </ReactFullpage.Wrapper>
          ) : (
            <ReactFullpage.Wrapper>
              <Wrapper className="section">
                <ContentBox>
                  <TopModules id="top-modules" className="item" />
                  <Concept
                    id="concept"
                    className="item"
                    scrollTop={scrollTop}
                  />
                  <div style={{ width: '100vw' }} id="network" ref={networkRef}>
                    <Network id="network" className="item" />
                  </div>
                  <div
                    style={{ width: '100vw' }}
                    id="mechanism"
                    ref={mechanismRef}
                  >
                    <Mechanism id="mechanism" className="item" />
                  </div>
                  <MechanismContentBox>
                    <RightMask />
                    <MechanismCard
                      url={firstData.url}
                      title={firstData.title}
                      desc={firstData.desc}
                      content={firstData.content}
                      isAction={pageDestination?.index !== 0}
                    />
                  </MechanismContentBox>
                </ContentBox>
              </Wrapper>

              {contentData.slice(1, 3).map((item, index) => (
                <Wrapper className="section" key={`section ${index + 2}`}>
                  <MechanismContentBox>
                    <RightMask />
                    <MechanismCard
                      url={item.url}
                      title={item.title}
                      desc={item.desc}
                      content={item.content}
                      isAction={pageDestination?.index !== index + 1}
                    />
                  </MechanismContentBox>
                </Wrapper>
              ))}

              <Wrapper className="section">
                <ContentBox>
                  <MechanismContentBox>
                    <RightMask />
                    <MechanismCard
                      url={lastData.url}
                      title={lastData.title}
                      desc={lastData.desc}
                      content={lastData.content}
                      isAction={pageDestination?.index !== 3}
                    />
                  </MechanismContentBox>
                  <OurTeam />
                  <Backed id="backed" className="item" />
                  <BottomModules />
                  <Footer />
                </ContentBox>
              </Wrapper>
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </Container>
  );
}

const contentData = [
  {
    url: '/video/protocol.mp4',
    title: 'AI customized blockchain',
    desc: 'LazAI is dedicated to delivering diverse AI services to both Web2 and Web3 users, including AI Agents, DeFAI, and AI Bots.',
    content: [
      {
        subtitle: 'Low Latency and High Performance',
        description:
          'LazChain leverages an enhanced BFT Consensus Protocol and a high-performance parallel execution engine to deliver near real-time transaction processing and efficient verification.',
      },
      {
        subtitle: 'Decentralized Data and Reasoning',
        description:
          'LazAI enables verification and traceability of off-chain data and reasoning, enhancing the platform’s fairness and trustworthiness.',
      },
      {
        subtitle: 'AI-Native Precompiled Modules',
        description:
          'The platform features built-in precompiled modules that support essential functions such as model training, inference, and data verification. These modules significantly reduce the cost of invoking smart contracts, streamlining the development process for developers.',
      },
      {
        subtitle: 'High-Performance Inference',
        description:
          'LazAI incorporates a robust native inference acceleration engine, ensuring low latency and high throughput to significantly enhance the real-time responsiveness of AI models.',
      },
      {
        subtitle: 'On-Chain Inference',
        description:
          'Powered by LazVM and a deeply optimized compute engine, LazChain enables efficient on-chain inference for small-scale models.',
      },
    ],
  },
  {
    url: '/video/iDAO.mp4',
    title: 'LazAI iDAO',
    desc: 'Designed to provide autonomous, transparent, and accountable governance. It empowers individuals or small teams to manage projects, track contributions, and interact with the broader LazAI network.',
    content: [
      {
        subtitle: 'Decentralized Governance',
        description:
          'Ensures fair, transparent decision-making and multi-party collaboration for data, models, and resources.',
      },
      {
        subtitle: 'Data Verification and Traceability',
        description:
          'Uses Quorum-Based BFT consensus to verify off-chain data and maintain secure, traceable records on-chain.',
      },
      {
        subtitle: 'Challenge Mechanisms',
        description:
          'Allows participants to verify data integrity and penalize malicious activities, with rewards for successful challenges',
      },
      {
        subtitle: 'The AI Agent Framework for Web3 Developers',
        description:
          'LazAI delivers a developer-friendly, multi-language AI agent framework deeply integrated with LazChain. It supports seamless integration with heterogeneous computing devices, remote/local model invocation, access to public and private data sources, and efficient storage with vector databases—empowering Web3 innovation like never before.',
      },
    ],
  },
  {
    url: '/video/full_nft.mp4',
    title: 'LAV protocol',
    desc: 'LAV is a blockchain-based system designed for validating logical assertions, such as the correctness of computations or the origin of training data. It operates on the following principles.',
    content: [
      {
        subtitle: 'For Off-Chain datasets, models, and Agents',
        description:
          ' The LAV is used minimally, only for publishing, time-stamping, financial transactions, and smart contracts; all computation, data storage, and arbitration are performed off-chain.',
      },
      {
        subtitle: 'Smart Arbitration and Consensus Mechanism',
        description:
          'LAV enables decentralized arbitration among stakeholders. Arbitrators vote to determine the truthfulness of the assertion, ensuring fairness and transparency within the system.',
      },
      {
        subtitle: 'Liquid Democracy',
        description:
          'LAV leverages Human-AI collaboration through Federated Byzantine Agreement to enable dynamic governance, combining liquid democracy with AI-driven efficiency. This streamlines decision-making, reducing time and cost while enhancing fairness and arbitration.',
      },
    ],
  },
  {
    url: '/video/token.mp4',
    title: 'DATA ANCHORING TOKEN',
    desc: 'DAT is a set of AI asset (Data, Model, or computing resources) standards aimed at utilizing verifiable computational algorithms, complemented by consensus-driven participation to establish their value, and incorporating time vectors to infuse these assets with greater vitality. ',
    content: [
      {
        subtitle: 'Tokenized Ownership',
        description:
          'Converts AI assets like data, models, and computing resources into tradable equity tokens. Enables users and organizations to hold and share ownership benefits through these tokens.',
      },
      {
        subtitle: 'Transparent and Traceable',
        description:
          'All AI assets registered as Data Anchoring Tokens are recorded on-chain for source traceability and verifiable contributions. Ensures transparency and credibility in asset transactions and usage.',
      },
    ],
  },
];
