import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { NetworkImageSvg, BigLogoSvg } from 'assets/img';
import TextAnimation from 'components/TextAnimation';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 53.88vw;
  position: relative;
  z-index: 1;
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;
  margin-top: 60px;
  padding: 60px 24px;
`;

const Title = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: Syne;
  font-size: 48px;
  font-weight: 700;
  height: 121.81px;
`;

const ImageBox = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  min-width: 1200px;
  max-width: 1400px;
  aspect-ratio: 100 / 52;
  position: relative;
  z-index: 1;
  margin-top: 40px;
  margin-bottom: 160px;
  border-radius: 42px;
  overflow: hidden;
`;

const MobileImageBox = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  z-index: 1;
`;

const NetworkImage = styled(NetworkImageSvg)`
  width: 100%;
  height: auto;
  position: relative;
  z-index: 1;
`;

const MobileNetworkImage = styled('img')`
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  gap: 40px;
  z-index: 1;
`;

const Logo = styled(BigLogoSvg)`
  width: 80%;
  height: auto;
`;

const MobileLogo = styled(BigLogoSvg)`
  width: 200px;
  height: auto;
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TopText = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: Syne;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -0.6px;
`;

const MobileTopText = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: Syne;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.6px;
`;

const BottomText = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: Syne;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -0.6px;
  text-decoration-line: underline;
`;

const MobileBottomText = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: Syne;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.6px;
  text-decoration-line: underline;
`;

interface SectionProps {
  id?: string;
  className?: string;
  scrollTop?: number;
}

const Concept: React.FC<SectionProps> = ({ id, className }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const { ref: inViewRef, inView } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 1.5;
      if (inView) {
        videoRef.current.play();
      } else {
        videoRef.current.currentTime = 0;
        videoRef.current.pause();
      }
    }
  }, [inView]);

  return (
    <Wrapper ref={inViewRef} id={id} className={className}>
      <video
        ref={videoRef}
        src={'/video/concept.mp4'}
        autoPlay={false}
        loop={false}
        muted
        playsInline
        style={{
          width: '100%',
          height: '100%',
        }}
      />
    </Wrapper>
  );
};

export default Concept;
