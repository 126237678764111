import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import {
  XSvg,
  TelegramSvg,
  MediumCircleFillSvg,
  FaqIcon1Svg,
  FaqIcon2Svg,
  FaqIcon3Svg,
  FaqIcon4Svg,
  FaqIcon5Svg,
  FaqIcon6Svg,
  FaqIcon7Svg,
  FaqIcon8Svg,
  FaqIcon9Svg,
  FaqIcon10Svg,
  BackButtonSvg,
} from 'assets/img';
import Header from 'components/Header';
import { throttle } from 'lodash';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import TextAnimation from 'components/TextAnimation';

const PageWrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  padding: 180px 120px 100px 120px;
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
  flex-direction: row;
  position: relative;

  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const MobileWrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
`;

const MobileContainer = styled.div`
  display: flex;
  overflow: hidden;
  gap: 40px;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  padding: 100px 28px;
`;

const Nav = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  transform: translate(50px, -50px);
  transition: transform 0.5s, opacity 0.5s;
  z-index: 99999;
  background: #010633;
  padding: 90px 24px 60px 24px;
  gap: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  &.open {
    pointer-events: auto;
    opacity: 1;
    transform: translate(0, 0);
  }
`;

const NavItem = styled(Typography)`
  display: block;
  color: var(--White, #fff);
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  transform: scaleY(0.5);
  transition: transform 0.5s, opacity 0.5s;
  opacity: 0;

  &.visible {
    transform: scaleY(1);
    opacity: 1;
  }
`;

const Mask = styled.img`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
`;

const FaqIcon1 = styled(FaqIcon1Svg)`
  width: 32px;
  height: auto;
`;

const BackButtonBox = styled(motion.div)`
  position: fixed;
  top: 100px;
  left: 120px;
  z-index: 999;
  cursor: pointer;
`;

const BackButton = styled(BackButtonSvg)`
  width: 140px;
  height: auto;
`;

const AnimationBox = styled(motion.div)`
  width: 100%;
`;

const TextBox = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 999;
  margin-left: 200px;
  margin-right: 60px;
  padding-bottom: 100px;
  gap: 60px;
`;

const MobileTextBox = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 999;
  padding-bottom: 100px;
  gap: 40px;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 999;
  gap: 18px;
`;

const SubSectionBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 999;
  gap: 10px;
  margin-left: 40px;
`;

const SubContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 999;
  gap: 10px;
`;

const Title = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 88px;
  font-style: normal;
  font-weight: 700;
  line-height: 96px;
  margin-bottom: 40px;
`;

const MobileTitle = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: 96px;
  margin-bottom: 10px;
`;

const SectionTitle = styled(Typography)`
  color: #fff;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
`;

const MobileSectionTitle = styled(Typography)`
  color: #fff;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
`;

const SubSectionTitle = styled(Typography)`
  color: #fff;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
`;

const MobileSubSectionTitle = styled(Typography)`
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
`;

const Content = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const MobileContent = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const Terms = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const onMenuClick = () => {
    setOpen(!open);
  };

  const handleNavigate = (sectionId: string) => {};

  const handleNavigateItem = (sectionId: string) => {
    setOpen(false);
    if (sectionId === 'Back') {
      navigate(-1);
    }
  };

  const goHomePage = () => {
    navigate('/landingPage');
  };

  const onBackButtonClick = () => {
    navigate(-1);
  };

  const variants = {
    hidden: {
      opacity: 0,
      y: 150,
      filter: 'blur(10px)',
    },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      filter: 'none',
      transition: {
        delay: i * 0.5,
        duration: 1,
      },
    }),
  };

  const fadeInVariants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
      filter: 'blur(10px)',
    },
    visible: (i: number) => ({
      opacity: 1,
      scale: 1,
      filter: 'none',
      transition: {
        delay: i * 0.5,
        duration: 1,
        ease: 'easeIn',
      },
    }),
  };

  return isMobile ? (
    <MobileWrapper>
      <Mask src={require('assets/img/mobile_faq_mask.png')} />
      <Header
        scrollTop={0}
        open={open}
        page="faq"
        onNavigate={handleNavigate}
        onMenuClick={onMenuClick}
        goHomePage={goHomePage}
      />
      <Nav className={open ? 'open' : ''}>
        {['Back'].map((item, index) => (
          <NavItem
            key={item}
            className={open ? 'visible' : ''}
            style={{ transitionDelay: `${index * 0.1}s` }}
            onClick={() => handleNavigateItem(item)}
          >
            {item}
          </NavItem>
        ))}
      </Nav>

      <MobileContainer>
        <MobileTextBox
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={0}
        >
          <MobileTitle>{TermsJson.title}</MobileTitle>

          {TermsJson.sections.map((section, index) => (
            <SectionBox key={index}>
              <MobileSectionTitle>{section.title}</MobileSectionTitle>

              {section.contents &&
                section.contents.map((contentStr, sectionIndex) => (
                  <SubContentBox key={sectionIndex}>
                    <MobileContent>{contentStr}</MobileContent>
                  </SubContentBox>
                ))}

              {section.subsections &&
                section.subsections.map((sub, subIndex) => (
                  <SubSectionBox key={subIndex}>
                    <MobileSubSectionTitle>{sub.title}</MobileSubSectionTitle>

                    {sub.contents.map((content, contentIndex) => (
                      <SubContentBox key={contentIndex}>
                        <MobileContent>{content}</MobileContent>
                      </SubContentBox>
                    ))}
                  </SubSectionBox>
                ))}
            </SectionBox>
          ))}
        </MobileTextBox>
      </MobileContainer>
    </MobileWrapper>
  ) : (
    <PageWrapper>
      <Mask src={require('assets/img/faq_mask.png')} />
      <BackButtonBox
        variants={fadeInVariants}
        initial="hidden"
        animate="visible"
        custom={0}
      >
        <BackButton onClick={onBackButtonClick} />
      </BackButtonBox>
      <Header
        scrollTop={0}
        open={open}
        page="faq"
        onNavigate={handleNavigate}
        onMenuClick={onMenuClick}
        goHomePage={goHomePage}
      />
      <TextBox
        variants={variants}
        initial="hidden"
        animate="visible"
        custom={1}
      >
        <Title>{TermsJson.title}</Title>

        {TermsJson.sections.map((section, index) => (
          <SectionBox key={index}>
            <SectionTitle>{section.title}</SectionTitle>

            {section.contents &&
              section.contents.map((contentStr, sectionIndex) => (
                <SubContentBox key={sectionIndex}>
                  <Content>{contentStr}</Content>
                </SubContentBox>
              ))}

            {section.subsections &&
              section.subsections.map((sub, subIndex) => (
                <SubSectionBox key={subIndex}>
                  <SubSectionTitle>{sub.title}</SubSectionTitle>
                  {sub.contents.map((content, contentIndex) => (
                    <SubContentBox key={contentIndex}>
                      <Content>{content}</Content>
                    </SubContentBox>
                  ))}
                </SubSectionBox>
              ))}
            {/* {section.contents && (
              section.contents.map((content, contentIndex) => (
                <SubSectionBox key={contentIndex}>
                  <Content>{content}</Content>
                </SubSectionBox>
              ))
            )} */}
          </SectionBox>
        ))}
      </TextBox>
    </PageWrapper>
  );
};

const TermsJson = {
  title: 'LazAI Terms',
  sections: [
    {
      title: 'Privacy Policy',
      contents: [
        "We value your privacy and are committed to protecting your personal information while you use this platform (hereinafter referred to as 'the platform'). This privacy policy explains how we collect, use, store, and share your information, as well as your rights regarding that information. By using our services, you agree to the terms of this privacy policy.",
      ],
    },
    {
      title: '1. Overview',
      contents: [
        'This privacy policy applies to all users who access and use the services of this platform, including but not limited to users accessing the platform through websites, applications, and APIs.',
      ],
      subsections: [
        {
          title: 'Privacy Protection Commitment',
          contents: [
            'We will not disclose relevant information regarding user access, including but not limited to browser fingerprints, Cookies, cache, etc.',
            'User signature data is only used for verification purposes and will be immediately destroyed after generating access credentials (such as JWT); it will not be stored.',
            'By accessing or using the platform services, you indicate your agreement to the content of this privacy policy.',
          ],
        },
      ],
    },
    {
      title: '2. Data Collection',
      subsections: [
        {
          title: '2.1 Types of Data Collected',
          contents: [
            'User Account Information: This includes blockchain addresses and user interaction data on the chain.',
            'User Input Data: This includes requests submitted by users through the interface and relevant data generated from on-chain interactions.',
            'System-Generated Data: This includes usage records and device information (such as IP addresses, access times, browser types, etc.).',
          ],
        },
        {
          title: '2.2 Purpose of Data Collection',
          contents: [
            'Provision of Services: Accelerating data viewing and assisting user decision-making. Providing Bot data analysis functionality.',
            'Improvement of Model Performance: Using anonymized data to optimize the Bot model.',
            'Support Platform Operations: Performing statistical analysis and user behavior analysis to improve user experience.',
          ],
        },
      ],
    },
    {
      title: '3. Data Usage',
      contents: [
        'Scope of Data Usage: User data may be used to improve model performance but will not be used for commercial purposes (such as advertising).',
        'Data Storage Methods: Data storage relies on publicly available data on the blockchain, and the platform will not store sensitive off-chain information. Only caching publicly available on-chain data necessary for accelerating access; data does not require encryption processing.',
        'Data Anonymization: User input data will be anonymized for model improvement, involving only relevant addresses without linking to actual identity information.',
      ],
    },
    {
      title: '4. Data Sharing and Third-Party Access',
      subsections: [
        {
          title: '4.1 Data Sharing Scenarios',
          contents: [
            'Data may be provided to internal analysis tools for platform performance optimization.',
            'We may share publicly available on-chain data when legally required (such as formal requests from government or law enforcement agencies).',
          ],
        },
        {
          title: '4.2 Data Sharing Restrictions',
          contents: [
            'We will not share data related to third-party addresses queried by users.',
            'Users should be aware that on-chain data is public information and should adequately protect the use of addresses and avoid association with real KYC information.',
          ],
        },
      ],
    },
    {
      title: '5. User Rights',
      subsections: [
        {
          title: '5.1 Data Access Rights',
          contents: [
            'Users can view their publicly available on-chain data, but the platform will only provide cached acceleration access services; any deletions, modifications, or shielding of on-chain data must be performed through on-chain operations.',
          ],
        },
        {
          title: '5.2 Data Export Rights',
          contents: [
            'The platform does not provide batch export services; users may query the required data through API but must bear the query costs.',
          ],
        },
      ],
    },
    {
      title: '6. Data Storage and Security',
      subsections: [
        {
          title: '6.1 Data Storage Location',
          contents: [
            'Data will be distributed and stored globally on servers and accessed via a CDN (Content Delivery Network) for expedited access.',
          ],
        },
        {
          title: '6.2 Data Encryption Measures',
          contents: [
            'All data in transit will be encrypted using standard SSL protocols to ensure security.',
          ],
        },
        {
          title: '6.3 Security Vulnerability Handling',
          contents: [
            'We will promptly identify and fix any discovered nDay security vulnerabilities to ensure user data safety.',
          ],
        },
        {
          title: '6.4 On-Chain Data Description',
          contents: [
            'The security and immutability of the data are guaranteed by the decentralized nature of the blockchain network.',
          ],
        },
      ],
    },
    {
      title: '7. Cookies and Tracking Technologies',
      subsections: [
        {
          title: '7.1 Use of Cookies',
          contents: [
            'The platform uses Cookies technology to record user preferences and conduct access statistics.',
          ],
        },
        {
          title: '7.2 Control Over Cookies',
          contents: [
            'Users can disable Cookies through their browser settings, but this may affect the usability of certain features.',
          ],
        },
      ],
    },
    {
      title: '8. Data Retention Period',
      subsections: [
        {
          title: '8.1 Retention of Cached Data',
          contents: [
            "Address-related data generated during users' first access will be cached for access acceleration.",
          ],
        },
        {
          title: '8.2 On-Chain Data Description',
          contents: [
            'On-chain data will be permanently stored as long as the blockchain continues to operate.',
          ],
        },
      ],
    },
    {
      title: '9. Data Transmission',
      subsections: [
        {
          title: '9.1 Cross-Border Data Transfer',
          contents: [
            'Data may be transmitted between different countries and regions.',
          ],
        },
        {
          title: '9.2 Legal Safeguards',
          contents: [
            'We comply with GDPR and other applicable data protection regulations to ensure the security and legality of cross-border data transfers.',
          ],
        },
      ],
    },
    {
      title: '10. Definitions',
      subsections: [
        {
          title: '10.1 Large Language Model (LLM)',
          contents: [
            'The core technology operating on the platform to support the AI Agent in providing intelligent interaction services.',
          ],
        },
        {
          title: '10.2 AI Agent',
          contents: [
            "An automated bot based on LLM and related settings, assisting users in process management, data interaction, and decision support. The AI Agent utilizes blockchain technology to ensure transparency and security during interactions. By instantaneously calling and analyzing on-chain data, the AI Agent provides personalized services, including but not limited to behavior prediction, data analysis, and intelligent recommendations based on the user's historical interaction records.",
          ],
        },
        {
          title: '10.3 User',
          contents: [
            "The users of the platform's services, including individual users, business users, and participants at different times.",
          ],
        },
        {
          title: '10.4 Service',
          contents: [
            'Includes model invocation, API interfaces, applications, and on-chain data visualization services, among others.',
          ],
        },
        {
          title: '10.5 Generated Content',
          contents: [
            'Texts, images, or other data content generated by the model.',
          ],
        },
        {
          title: '10.6 iDAO',
          contents: [
            'A data structure that records snapshots of real-world organizations on the blockchain.',
          ],
        },
      ],
    },
    {
      title: '11. Service Content and Usage Rules',
      subsections: [
        {
          title: '11.1 Platform Services',
          contents: [
            'Provide organized data for coDAOs, iDAOs, and FullNFTs, presented to users in a visual format.',
          ],
        },
        {
          title: '11.2 Usage Conditions',
          contents: [
            'Users can access basic features for free.',
            'Users can choose customized features or unlock advanced features by holding FullNFTs.',
            'There is a limit of 3 requests per second per single.',
          ],
        },
        {
          title: '11.3 Service Restrictions',
          contents: [
            'The platform prohibits users from using the services for any illegal or unethical activities.',
            'The platform reserves the right to block, restrict, or terminate any illegal content.',
          ],
        },
      ],
    },
    {
      title: '12. Fees and Subscriptions',
      contents: [
        "Services generate revenue based on the platform's economic model. Using the platform for on-chain interactions may make the platform a participant in a coDAO, thereby sharing in on-chain revenue.",
      ],
    },
    {
      title: '13. User Behavior Regulations',
      contents: [
        'Prohibited Content: The upload of illegal, infringing, or harmful content is prohibited.',
        'Service Abuse: The misuse of services through technical means (such as hacking the model or excessive API calls) is forbidden.',
        'The platform reserves the right to ban users who violate these rules from accessing data visualization interfaces.',
        'Users must comply with the laws and regulations of their jurisdictions.',
      ],
    },
    {
      title: '14. Limitation of Platform Liability',
      subsections: [
        {
          title: '14.1 Data Accuracy',
          contents: [
            'The on-chain data provided by the platform may contain bias or be incomplete; users must verify important decision-related data independently.',
          ],
        },
        {
          title: '14.2 Service Interruptions and Data Errors',
          contents: [
            'The platform does not guarantee the persistence and stability of third-party blockchain data.',
            'The platform will make efforts to fix errors or service interruptions due to technical issues but will not assume liability for damages.',
          ],
        },
      ],
    },
    {
      title: '15. Changes and Termination of Services',
      subsections: [
        {
          title: '15.1 Modification, Suspension, or Termination',
          contents: [
            'The platform reserves the right to modify, suspend, or terminate services.',
          ],
        },
        {
          title: '15.2 User Responsibility',
          contents: [
            "Violating users' data may be blocked, with responsibility resting solely on the user.",
          ],
        },
      ],
    },
    {
      title: '16. Applicable Law and Dispute Resolution',
      contents: [
        'Disputes should first be resolved through negotiation; if negotiation fails, arbitration or litigation may be pursued.',
      ],
    },
    {
      title: '17. Amendment of Terms',
      subsections: [
        {
          title: '',
          contents: [
            '17.1 The platform reserves the right to modify these Terms at any time, with notifications sent to users via email or pop-up alerts upon changes.',
          ],
        },
        {
          title: '',
          contents: [
            '17.2 Continued use of services indicates acceptance of the modified Terms.',
          ],
        },
      ],
    },
    {
      title: '18. Updates to the Privacy Policy',
      contents: [
        'We reserve the right to update the privacy policy at any time. Any updates will be communicated to users through announcements or email notifications.',
      ],
    },
    {
      title: '19. Legal Compliance',
      contents: [
        'This platform complies with applicable provisions of GDPR (General Data Protection Regulation) and CCPA (California Consumer Privacy Act).',
      ],
    },
    {
      title: 'Special Provisions for Minors',
      contents: [
        'Users on the platform interact through blockchain addresses, which do not allow for age identification; therefore, no distinction is made between minors and adults.',
      ],
    },
  ],
};

export default Terms;
