import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import anime from 'animejs';
import styled from 'styled-components';
import './index.css';

const Wrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  width: 100%;
`;

const TextBox = styled(Box)<{
  fontSize: string;
  fontWeight: number;
  gradient: string;
  fontFamily: string;
  color?: string;
  textAlign?: string;
}>`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-family: ${({ fontFamily }) => fontFamily};
  text-align: ${({ textAlign }) => textAlign || 'center'};
  ${({ color, gradient }) =>
    color
      ? `color: ${color};`
      : `
        background: ${gradient};
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;
      `}
  position: relative;
  display: inline-block;
  white-space: pre-line;
  will-change: transform, opacity;
`;

interface TextAnimationProps {
  text: string;
  fontSize?: string;
  fontWeight?: number;
  gradient?: string;
  fontFamily?: string;
  color?: string;
  loop?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  animationDelay?: number;
  animationDuration?: number;
  waveEffect?: boolean;
}

const TextAnimation: React.FC<TextAnimationProps> = ({
  text,
  fontSize = '68px',
  fontWeight = 700,
  gradient = '',
  fontFamily = 'Syne',
  color = '#fff',
  loop = false,
  textAlign = 'center',
  animationDelay = 70,
  animationDuration = 600,
  waveEffect = false,
}) => {
  const textRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (textRef.current) {
      const textWrapper = textRef.current;
      textWrapper.innerHTML =
        textWrapper.textContent?.replace(
          /\S/g,
          "<span class='letter'>$&</span>",
        ) || '';

      const timeline = anime.timeline({
        loop,
        complete: () => {
          if (!loop) {
            anime.set(textWrapper, { opacity: 1 });
          }
        },
      });

      timeline.add({
        targets: textWrapper.querySelectorAll('.letter'),
        scale: [0.3, 1],
        opacity: [0, 1],
        translateZ: 0,
        easing: 'easeOutExpo',
        duration: animationDuration,
        delay: (el, i) => animationDelay * (i + 1),
      });

      if (waveEffect) {
        timeline.add({
          targets: textWrapper.querySelectorAll('.letter'),
          scaleX: [0, 1],
          opacity: [0.5, 1],
          easing: 'easeOutExpo',
          duration: animationDuration,
          offset: `-=${animationDuration / 2}`,
          delay: (el, i, l) => animationDelay * (l - i),
        });
      }

      timeline.add({
        targets: textWrapper,
        opacity: loop ? 0 : 1,
        duration: 1000,
        easing: 'easeOutExpo',
        delay: 1000,
      });
    }
  }, [text, loop, animationDelay, animationDuration, waveEffect]);

  return (
    <Wrapper>
      <TextBox
        component="h1"
        fontSize={fontSize}
        fontWeight={fontWeight}
        gradient={gradient}
        fontFamily={fontFamily}
        color={color}
        textAlign={textAlign}
      >
        <Box component="span" className="letters" ref={textRef}>
          {text}
        </Box>
      </TextBox>
    </Wrapper>
  );
};

export default TextAnimation;
