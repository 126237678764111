import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import {
  XSvg,
  TelegramSvg,
  MediumCircleFillSvg,
  FaqIcon1Svg,
  FaqIcon2Svg,
  FaqIcon3Svg,
  FaqIcon4Svg,
  FaqIcon5Svg,
  FaqIcon6Svg,
  FaqIcon7Svg,
  FaqIcon8Svg,
  FaqIcon9Svg,
  FaqIcon10Svg,
  BackButtonSvg,
} from 'assets/img';
import Header from 'components/Header';
import { throttle } from 'lodash';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import TextAnimation from 'components/TextAnimation';
import { TelegramLink, MediumLink, XLink } from 'constant';

const PageWrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  padding: 180px 120px 100px 120px;
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
  flex-direction: row;
  position: relative;
`;

const MobileWrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
`;

const MobileContainer = styled.div`
  display: flex;
  overflow: hidden;
  gap: 40px;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  padding: 100px 28px;
`;

const Nav = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  transform: translate(50px, -50px);
  transition: transform 0.5s, opacity 0.5s;
  z-index: 99999;
  background: #010633;
  padding: 90px 24px 60px 24px;
  gap: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  &.open {
    pointer-events: auto;
    opacity: 1;
    transform: translate(0, 0);
  }
`;

const NavItem = styled(Typography)`
  display: block;
  color: var(--White, #fff);
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  transform: scaleY(0.5);
  transition: transform 0.5s, opacity 0.5s;
  opacity: 0;

  &.visible {
    transform: scaleY(1);
    opacity: 1;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  z-index: 999;
  position: relative;
`;

const MobileTitleBox = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  z-index: 999;
`;

const Title = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 68px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  margin-bottom: 40px;
`;

const MobileTitle = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 20px;
`;

const Desc = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 20px;
  min-width: 410px;
`;

const MobileDesc = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 20px;
`;

const SocialIcons = styled(motion.div)`
  display: flex;
  gap: 16px;
`;

const X = styled(XSvg)`
  width: 24px;
  height: auto;
`;

const Telegram = styled(TelegramSvg)`
  width: 24px;
  height: auto;
`;

const MediumCircleFill = styled(MediumCircleFillSvg)`
  width: 24px;
  height: auto;
`;

const QuestionsContainer = styled(motion.div)`
  flex: 1 1 auto;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  border-radius: 8px;
  z-index: 999;
  position: relative;
`;

const MobileQuestionsContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  z-index: 999;
`;

const MiddleBox = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
`;
const MobileMiddleBox = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 999;
  position: relative;
  flex-direction: center;
  align-items: center;
`;

const ImageBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  aspect-ratio: 100 / 100;
  transition: filter 0.3s ease;
  border-radius: 40px;
  border: 2px solid #304f91;
`;

const MobileImageBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 64px;
  aspect-ratio: 100 / 100;
  transition: filter 0.3s ease;
  border-radius: 26px;
  border: 2px solid #304f91;
  position: relative;
  background: #00092f;
  z-index: 999;
`;

const MiddleLine = styled.div`
  width: 2px;
  height: 60vh;
  background: rgba(96, 148, 243, 0.5);
`;

const MobileMiddleLine = styled.div`
  height: 2px;
  width: 100%;
  background: rgba(96, 148, 243, 0.5);
  position: absolute;
  top: 50%;
`;

const StyledAccordion = styled(Accordion)`
  background-color: transparent;
  border: none;
  box-shadow: none;

  &::before,
  &::after {
    content: '';
    display: block;
    height: 1px;
    background-color: rgba(23, 92, 217, 0.5);
    border: none;
    box-shadow: none;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  color: #fff;
  font-family: Syne;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const MobileAccordionSummary = styled(AccordionSummary)`
  color: #fff;
  font-family: Syne;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  padding: 16px 0;
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-bottom: none;
`;

const MobileStyledAccordionDetails = styled(AccordionDetails)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-bottom: none;
  padding: 16px 0;
`;

const Mask = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const FaqIcon1 = styled(FaqIcon1Svg)`
  width: 32px;
  height: auto;
`;

const BackButtonBox = styled(motion.div)`
  position: absolute;
  top: 100px;
  left: 120px;
  z-index: 999;
  cursor: pointer;
`;

const BackButton = styled(BackButtonSvg)`
  width: 140px;
  height: auto;
`;

const AnimationBox = styled(motion.div)`
  width: 100%;
`;

const FAQ = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const faqIcons = [
    FaqIcon1Svg,
    FaqIcon2Svg,
    FaqIcon3Svg,
    FaqIcon4Svg,
    FaqIcon5Svg,
    FaqIcon6Svg,
    FaqIcon7Svg,
    FaqIcon8Svg,
    FaqIcon9Svg,
    FaqIcon10Svg,
  ];

  const [scrollTop, setScrollTop] = useState(0);
  const [open, setOpen] = useState(false);
  const [currentIconIndex, setCurrentIconIndex] = useState(0);

  // useEffect(() => {
  //   const container = containerRef.current;

  //   const handleScroll = throttle(() => {
  //     if (container) {
  //       const { scrollTop, scrollHeight, clientHeight } = container;
  //       setScrollTop(scrollTop);

  //       const totalIcons = faqIcons.length;
  //       const maxScroll = scrollHeight - clientHeight;
  //       const iconIndex = Math.min(
  //         Math.floor((scrollTop / maxScroll) * totalIcons),
  //         totalIcons - 1,
  //       );
  //       setCurrentIconIndex(iconIndex);
  //     }
  //   }, 100);

  //   container?.addEventListener('scroll', handleScroll);

  //   return () => {
  //     container?.removeEventListener('scroll', handleScroll);
  //   };
  // }, [faqIcons.length]);

  const CurrentIcon = faqIcons[currentIconIndex];

  const onMenuClick = () => {
    setOpen(!open);
  };

  const handleNavigate = (sectionId: string) => {};

  const handleNavigateItem = (sectionId: string) => {
    setOpen(false);
    if (sectionId === 'Back') {
      navigate(-1);
    }
  };

  const goHomePage = () => {
    navigate('/landingPage');
  };

  const onBackButtonClick = () => {
    navigate(-1);
  };

  const variants = {
    hidden: {
      opacity: 0,
      y: 150,
    },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.5,
        duration: 1,
      },
    }),
  };

  const fadeInVariants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
      filter: 'blur(10px)',
    },
    visible: (i: number) => ({
      opacity: 1,
      scale: 1,
      filter: 'none',
      transition: {
        delay: i * 0.5,
        duration: 1,
        ease: 'easeIn',
      },
    }),
  };

  return isMobile ? (
    <MobileWrapper>
      <Mask src={require('assets/img/mobile_faq_mask.png')} />
      <Header
        scrollTop={0}
        open={open}
        page="faq"
        onNavigate={handleNavigate}
        onMenuClick={onMenuClick}
        goHomePage={goHomePage}
      />
      <Nav className={open ? 'open' : ''}>
        {['Back'].map((item, index) => (
          <NavItem
            key={item}
            className={open ? 'visible' : ''}
            style={{ transitionDelay: `${index * 0.1}s` }}
            onClick={() => handleNavigateItem(item)}
          >
            {item}
          </NavItem>
        ))}
      </Nav>

      <MobileContainer>
        <MobileTitleBox>
          <AnimationBox
            variants={variants}
            initial="hidden"
            animate="visible"
            custom={0}
          >
            <MobileTitle>Frequently Asked Questions</MobileTitle>
          </AnimationBox>
          <AnimationBox
            variants={variants}
            initial="hidden"
            animate="visible"
            custom={1}
          >
            <MobileDesc>
              Can’t find a answer?
              <br />
              Feel free to contact us on the social media.
            </MobileDesc>
          </AnimationBox>
          <SocialIcons
            variants={variants}
            initial="hidden"
            animate="visible"
            custom={2}
          >
            <IconButton href={TelegramLink} target="_blank" color="inherit">
              <Telegram />
            </IconButton>
            <IconButton href={MediumLink} target="_blank" color="inherit">
              <MediumCircleFill />
            </IconButton>
            <IconButton href={XLink} target="_blank" color="inherit">
              <X />
            </IconButton>
          </SocialIcons>
        </MobileTitleBox>

        <MobileMiddleBox
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={3}
        >
          <MobileImageBox>
            <FaqIcon1 />
          </MobileImageBox>
          <MobileMiddleLine />
        </MobileMiddleBox>

        <MobileQuestionsContainer
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={4}
        >
          {contentList.map((item, idx) => (
            <StyledAccordion key={idx}>
              <MobileAccordionSummary
                expandIcon={
                  <ExpandMoreIcon fontSize="medium" sx={{ color: '#fff' }} />
                }
                aria-controls="panel1-content"
                id="panel1-header"
              >
                {item.question}
              </MobileAccordionSummary>
              <MobileStyledAccordionDetails>
                {item.answer}
              </MobileStyledAccordionDetails>
            </StyledAccordion>
          ))}
        </MobileQuestionsContainer>
      </MobileContainer>
    </MobileWrapper>
  ) : (
    <PageWrapper>
      <Mask src={require('assets/img/faq_mask.png')} />
      <BackButtonBox
        variants={fadeInVariants}
        initial="hidden"
        animate="visible"
        custom={0}
      >
        <BackButton onClick={onBackButtonClick} />
      </BackButtonBox>
      <Header
        scrollTop={0}
        open={open}
        page="faq"
        onNavigate={handleNavigate}
        onMenuClick={onMenuClick}
        goHomePage={goHomePage}
      />
      <TitleBox>
        <TextAnimation
          text={`Frequently\nAsked\nQuestions`}
          fontSize="68px"
          textAlign="left"
        />
        <AnimationBox
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={1}
        >
          <Desc>
            Can’t find a answer?
            <br />
            Feel free to contact us on the social media.
          </Desc>
        </AnimationBox>
        <SocialIcons
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={2}
        >
          <IconButton href={TelegramLink} target="_blank" color="inherit">
            <Telegram />
          </IconButton>
          <IconButton href={MediumLink} target="_blank" color="inherit">
            <MediumCircleFill />
          </IconButton>
          <IconButton href={XLink} target="_blank" color="inherit">
            <X />
          </IconButton>
        </SocialIcons>
      </TitleBox>

      <MiddleBox
        variants={fadeInVariants}
        initial="hidden"
        animate="visible"
        custom={3}
      >
        <ImageBox>
          <CurrentIcon />
        </ImageBox>
        <MiddleLine />
      </MiddleBox>

      <QuestionsContainer
        ref={containerRef}
        variants={variants}
        initial="hidden"
        animate="visible"
        custom={4}
      >
        {contentList.map((item, idx) => (
          <StyledAccordion key={idx}>
            <StyledAccordionSummary
              expandIcon={
                <ExpandMoreIcon fontSize="large" sx={{ color: '#fff' }} />
              }
              aria-controls="panel1-content"
              id="panel1-header"
            >
              {item.question}
            </StyledAccordionSummary>
            <StyledAccordionDetails>{item.answer}</StyledAccordionDetails>
          </StyledAccordion>
        ))}
      </QuestionsContainer>
    </PageWrapper>
  );
};

const contentList = [
  {
    question: '1. What is LazAI?',
    answer: (
      <>
        LazAI solves AI’s data alignment problem with a blockchain solution that
        leverages innovative verified computing technology, reimagining of the
        DAO to the individual-centric DAO (iDAO) to evolve organizational
        boundaries, and a blockchain not of ledgers of transactions but of a
        proprietary assetization standard dubbed the Data Anchoring Token (DAT).
        Through this novel architecture, LazAI enables open access to create
        Web3 AI applications in composable environments with proper data, paving
        the way for a new AI+Web3 paradigm.
      </>
    ),
  },
  {
    question: '2. Which problem does LazAI solve?',
    answer: (
      <>
        LazAI addresses the issue of alignment between AI and humans.
        <br />
        <br />
        {`Among the three key elements of AI (model, data, inference results),
        data is the weakest and most challenging aspect to manage. The problem
        of aligning AI with human values is fundamentally a data issue. With the
        rapid development of AI, there is a general consensus that the data
        available for training is nearing exhaustion, and the inference
        capabilities of AI need significant enhancement. This perspective is not
        incorrect, but we believe that the widespread application of large
        models and the monopolization of user data by big companies have led to
        serious data biases. Meeting the specific needs of particular groups in
        a universal model is challenging and often requires considerable time
        and effort. Because when large general models are trained on massive
        datasets, they will inevitably opt for results that resonate with the
        majority's opinions, and the proportion of this "majority" is constantly
        changing. This dynamic nature is the fundamental reason we often mention
        the difficulty of aligning AI with humans. We believe that the beauty of
        the world lies in its diversity, and all group perspectives deserve
        respect. In the realm of computing, human perspectives or user behaviors
        are projected as data. Therefore, how to extract value from this diverse
        mass of data and convey it to users should be our focus. In other words,
        as long as all groups can capture relevant value from this data (where
        the significance of that value should be determined by consensus), we
        are indeed progressing towards aligning AI with humans.`}
        <br />
        <br />
        Thus, we believe that all current issues faced by AI ultimately converge
        on small models or model fine-tuning and the value screening of data.
        The design of LazAI follows this ultimate direction, aiming to enable
        organic participation from all groups through a decentralized blockchain
        approach to ultimately capture the true value of data.
      </>
    ),
  },
  {
    question: '3. How does LazAI address the issue of AI data alignment?',
    answer: (
      <>
        We believe that there are two key points to solving the problem of AI
        data alignment: the way value data is generated and the capture and
        transmission of that value.
        <br />
        <br />
        In traditional data generation models, we observe that nearly all
        well-known companies or groups operate within organizational boundaries.
        Users in these settings typically act as digital farmers, contributing
        their data to larger organizations, with most of the benefits being
        difficult to directly access for end users, particularly in the AI
        product sphere. Therefore, we aim to change this model and boundary
        setting from an organization-centered approach to an individual-centered
        one. We envision that any individual, with the assistance of AI, can
        autonomously generate and manage their own data, thereby shrinking
        boundaries from organizations to individuals. Each individual can freely
        expand until they form independent communities, ultimately creating a
        network. Individuals within these communities will possess strong
        personal identity labels and can develop freely within this network
        without needing permission. We also hope to define the assessment of
        data value through community consensus rather than relying on a specific
        organization for this purpose. Of course, to improve efficiency, we will
        introduce a nodal mechanism, but the formation of nodes will also be
        determined through community consensus, incorporating a reward and
        punishment system to enhance their effectiveness. This organization
        structure, more aligned with social sciences, will greatly accommodate
        data diversity and provide these diversities with infinite
        possibilities. We define these communities as Individual-centric DAOs
        (iDAOs) compared to traditional organizational and governance
        structures.
        <br />
        <br />
        Once value data is generated, the second key point we contemplate is how
        to capture and transmit that value. In the wave of AI development, there
        is a widespread view that AI and blockchain possess a natural
        combinatory attribute, and this is indeed true. During AI application,
        diverse and complex value data (which encompasses any of the three
        elements) is generated everywhere and at all times. The most effective
        way to capture its value is to assetize it. In contrast to the opacity
        in traditional AI processes, the openness and transparency of blockchain
        provide a natural complement. We aspire to establish a set of AI asset
        standards through verifiable computational algorithms, with the
        involvement of consensus to establish their value and provide a temporal
        vector, granting these assets greater vitality. This allows assetized
        value data to be better utilized and transmitted, thus genuinely helping
        all individuals capture the value of the data they generate. We define
        this type of data-anchored asset as Data Anchoring Token (DAT, formerly
        known as Full NFT).
      </>
    ),
  },
  {
    question: '4. How were these issues addressed in the past?',
    answer: (
      <>
        Based on research, there were primarily two approaches used in the past
        to solve similar problems. The first approach involved authoritative
        institutions for data filtering. This method was effective in improving
        efficiency but neglected the needs of minority groups and had a negative
        impact on data diversity, failing to retain some value data recognized
        by those minority groups.
        <br />
        <br />
        The second approach employed comprehensive data scraping, which
        naturally considered data diversity. However, this method had certain
        shortcomings in defining the value of data. It could not accurately
        determine the magnitude of data value, leading to misalignment in value
        capture and even directly affecting the earnings of end users.
        <br />
        <br />
        LazAI seeks to integrate these two approaches by redefining
        organizational boundaries through an innovative social science
        perspective. While focusing on individuals, it aims to organically merge
        individual and organizational consensus, employing unique verifiable
        algorithms to better utilize value data, and thus consistently advance
        towards aligning AI with human values.
      </>
    ),
  },
  {
    question: "5. What is LazAI's solution?",
    answer: (
      <>
        LazAI will adopt a modular and layered architecture:
        <br />
        <br />
        Application Layer: The application layer provides users with a variety
        of decentralized applications (DApps), covering services such as AI
        Agents, Marketplace, Meme Coin launch platforms, DeFAI, and more.
        <br />
        <br />
        AI Execution Extension Layer (ExEx Layer): This layer is built on the
        high-performance LazAI Blockchain Network, offering an efficient and
        trustworthy decentralized processing platform for the release and
        circulation of AI Assets. Additionally, LazAI provides various on-chain
        AI capabilities, including Onchain Small Models, Micro Agents, LazAI
        Flow, and Data Anchoring Tokens.
        <br />
        <br />
        Interaction Layer: The interaction layer offers robust protocols and
        tools that support diverse interactions between users and LazChain, as
        well as its protocols and platforms.
        <br />
        <br />
        Through this modular, multi-layered technical architecture, the LazAI
        platform aims to create an open and highly collaborative AI + Blockchain
        ecosystem. This architecture is designed to optimize user experience,
        protect data privacy, enable smart execution, and facilitate
        decentralized transactions, ensuring efficient management and
        transparency throughout the entire AI lifecycle.
      </>
    ),
  },
  {
    question:
      '6. What differentiated Advantages of LazAI Compared to Existing Technologies',
    answer: (
      <>
        One-Stop AI Data Support
        <br />
        <br />
        LazAI significantly lowers the development threshold through a
        comprehensive toolchain and data integration capabilities, offering
        end-to-end support from data upload to smart contract generation. Key
        innovations include:
        <br />
        <br />
        Breaking Data Silos, Providing Trusted AI Data: LazAI offers a complete
        data validation and integration mechanism, combining on-chain and
        off-chain interactions with decentralized consensus (Quorum-Based BFT
        consensus protocol) to ensure data transparency and reliability.
        <br />
        <br />
        Full-Process Verifiability of AI Models: LazAI supports Off-Chain,
        OP-based Verifiable, and ZK-based Verifiable methods, ensuring the
        security and reliability of datasets, models, and agents throughout
        their lifecycle. This mechanism guarantees the transparency and
        verifiability of AI assets, promoting the implementation of trustworthy
        AI applications.
        <br />
        <br />
        Logical Assertion Verification (LAV): LazAI introduces LAV technology to
        ensure the computation process and sources of data, models, and agents
        are verified. LAV utilizes blockchain timestamps and smart contracts to
        provide reliable logical assertion verification during data flow and
        model training, enhancing model transparency and trustworthiness.
        <br />
        <br />
        Comprehensive Toolchain Support: LazAI delivers a one-stop toolchain
        from data processing, model development, to deployment (such as
        LazToolkits and cross-language SDKs), significantly improving
        development efficiency and simplifying the development process.
        <br />
        <br />
        <br />
        First AI Chain Designed for Large-Scale Implementation
        <br />
        <br />
        LazChain provides unique full-stack AI support, allowing seamless
        completion of AI data processing and inference, helping developers
        efficiently realize the quick deployment of intelligent applications.
        Innovations include:
        <br />
        <br />
        Data Anchoring Token (DAT): LazAI addresses AI data alignment issues
        through a novel assetization standard (DAT), assisting developers and
        users in creating pure Web3 AI applications and leading the new trend of
        AI + Web3.
        <br />
        <br />
        Off-Chain Training and On-Chain Verification: LazAI supports off-chain
        model training and on-chain verification to ensure data privacy. By
        integrating LAV and Point of View (PoV) technologies, it guarantees
        reliable logical verification in AI model validation, leveraging rich
        data support from community and social oversight. This dual-verification
        mechanism ensures AI model reliability and transparency across multiple
        dimensions.
        <br />
        <br />
        AI Agent Framework: LazAI provides a developer-friendly, multi-language
        Agent framework integrated with LazChain, accommodating heterogeneous
        computing devices, remote/local model calls, and public/private data
        access and vector database storage.
        <br />
        <br />
        High-Performance Inference: LazAI features a powerful native inference
        acceleration engine, delivering low-latency and high-throughput
        inference services to enhance real-time responsiveness of AI models.
        On-chain inference is supported through LazVM and a deeply optimized
        computing engine for small models.
        <br />
        <br />
        <br />
        Ultra-High-Performance Real-Time AI Chain
        <br />
        <br />
        {`LazChain is designed with AI optimization for the first
        ultra-high-performance real-time AI blockchain, balancing performance,
        efficiency, and privacy. Compared to other platforms, LazAI's
        innovations in the underlying network and data modules offer significant
        advantages in supporting AI applications:`}
        <br />
        <br />
        First High-Performance Real-Time AI Chain: The decentralized and
        efficient consensus layer employs a Quorum-Based BFT consensus
        mechanism, dispersing data governance and storage across multiple
        decentralized quorum organizations, ensuring reliability, transparency,
        and efficiency in data handling.
        <br />
        <br />
        Efficient Verifiable Settlement Layer: LazChain supports
        high-performance virtual machines and execution frameworks, providing a
        robust, flexible, and traceable technological foundation for the
        registration, management, and trading of data, models, and agents while
        employing ZK and TEE technologies to ensure transaction privacy and
        trustworthiness of computational results.
        <br />
        <br />
        Flexible, Low-Threshold AI Execution Extension Layer: LazAI offers an
        iDAO framework and development tools supporting off-chain data
        processing, model development, and deployment. Seamless interaction with
        LazChain ensures rapid circulation and verification of AI assets,
        lowering participation thresholds for developers and users.
        <br />
        <br />
        {`Low-Cost, Highly Decoupled Data Access Layer: LazAI ensures data
        integrity of off-chain data through a consensus verification mechanism
        and records data hashes on-chain, enabling efficient tracing and
        validation. The iDAO and Quorum organizations facilitate interaction
        with off-chain third-party data sources (such as IPFS and Web2 data
        centers), ensuring data transmission's security and transparency while
        dynamically assessing data source credibility to ensure the circulation
        and utilization of high-quality data.`}
        <br />
        <br />
        <br />
        Scalable Layer
        <br />
        <br />
        {`LazAI's technical architecture is highly scalable, supporting multiple
        programming languages and platforms to enhance developer experience and
        ecosystem compatibility:`}
        <br />
        <br />
        Multi-Language Support: LazAI supports writing smart contracts and
        expanding Agent tools in multiple languages, providing a flexible
        development environment and enhancing compatibility with different
        technology stacks and external platforms.
        <br />
        <br />
        Rapid Ecosystem Compatibility: The LazAI ecosystem rapidly integrates
        with external systems such as AI and Web3, facilitating the extensive
        application.
      </>
    ),
  },
  // {
  //   question: "How about LazAI's user Engagement?",
  //   answer: (
  //     <>
  //       Before the establishment of the LazAI testnet, we have designed several
  //       scenarios for early users to participate and contribute, ensuring that
  //       the overall narrative is clearly articulated and thoroughly explained
  //       throughout this process.
  //       <br />
  //       <br />
  //       <br />
  //       {`1.Loyalty Points Program: This program aims to gradually guide users in
  //       understanding LazAI's main narrative through several simple and engaging
  //       AI use cases. A points system will be designed to encourage users to
  //       participate actively and enhance their loyalty, ultimately creating
  //       effective incentives. The first scenario of this program will be a
  //       mini-program on Telegram called PicWiz.`}
  //       <br />
  //       <br />
  //       2.AI Agent Framework: AI Agents have become a growing trend in the
  //       current market landscape. LazAI has developed a comprehensive
  //       open-source framework at the application layer, providing the community
  //       with tools to explore diverse AI scenarios. We will also align with
  //       market trends by integrating meme culture and DeFAI to develop more
  //       effective agents for opinion output and interaction with human users,
  //       fostering an interesting and vibrant technical community.
  //       <br />
  //       <br />
  //       <br />
  //       What Can Users Do Now?
  //       <br />
  //       <br />
  //       {`Participate in the Loyalty Points Program: Users can engage with various
  //       AI use cases to earn points and enhance their understanding of LazAI's
  //       ecosystem.`}
  //       <br />
  //       <br />
  //       Explore the AI Agent Framework: Users can leverage the open-source
  //       framework to create and experiment with their own AI agents,
  //       contributing to the evolving landscape of AI applications.
  //       <br />
  //       <br />
  //       Join the Community: Users can become part of a growing technical
  //       community, interact with developers and other users, and contribute to
  //       discussions and developments around AI and blockchain technologies.
  //       <br />
  //       <br />
  //       <br />
  //       These strategies focus on building a vibrant user community while
  //       enabling users to actively participate in the growth and development of
  //       the LazAI ecosystem.
  //     </>
  //   ),
  // },
  {
    question: '7. How about LazAI Token Economics and Token Model?',
    answer: (
      <>
        LazAI will adopt a three-token model:
        <br />
        <br />
        1.Data Anchoring Token (DAT):
        <br />
        <br />
        This token represents the assetization of value data, driven by user
        data and consensus validation of computational outputs. DAT serves as a
        mechanism to capture and validate the value generated from diverse data
        sources.
        <br />
        <br />
        2.Computing Gas Token (CGT):
        <br />
        <br />
        The CGT is the token tied to the computation resources consumed by AI
        applications. It facilitates the user interaction with AI, generating
        inference results through the processing of requests and operations on
        the LazAI network.
        <br />
        <br />
        3.LazAI Token (LAZ):
        <br />
        <br />
        The LAZ token serves as the network protocol token, functioning as the
        coinbase of the LazAI network. It is responsible for gas consumption,
        governance, and other functions that fulfill the requirements of a
        blockchain ecosystem.
      </>
    ),
  },
  // {
  //   question: 'How does a Full NFT differ from a traditional NFT?',
  //   answer:
  //     'Traditional NFTs typically anchor one discrete object (artwork, collectible) and remain relatively static.\nFull NFTs incorporate multiple related points—often including “human nodes”—into a dynamic, interactive environment. They emphasize interconnectedness, updateability, and multi-node anchoring.\nIn essence, Full NFTs represent a “big picture,” whereas standard NFTs highlight isolated items.',
  // },
  // {
  //   question: 'How can Full NFTs be used in other projects?',
  //   answer:
  //     'Because they highlight comprehensive relationships, Full NFTs can:\nCombine Existing NFTs: Merging static tokens into a broader, interconnected data tapestry—be it a supply chain map, multi-author projects, or community interactions.\nAnchor People and Data: Full NFTs form a self-contained loop, embedding the who, what, and how. This fosters synergy, turning intangible social connections into verifiable on-chain structures.\nPersonalize and Extend: Each user or project can tailor how data appears, ensuring dynamic snapshots reflect real-time participation or evolving RWA anchors.',
  // },
  // {
  //   question: 'Does LazAI have commercial applications?',
  //   answer:
  //     'Yes. LazAI’s ecosystem—centered on iDAO, coDAO, Full NFTs, and verifiable computing—supports:\nData Organization & Decision-Making: High-fidelity chain mappings give enterprises clarity, ensuring data is trustworthy and perspective-driven.\nRWA Anchoring & Asset Management: Transparent references reduce friction in supply chains, IP management, or financial instruments.\nCommunity-Driven Structures: Through iDAO, teams can lower overhead by automating routine tasks and capturing real-time user feedback for strategic pivots or expansions.',
  // },
  // {
  //   question: 'Does LazAI have existing use cases like “PicWiz DAO”?',
  //   answer:
  //     'Yes. PicWiz DAO is a minimal initial prototype. Although it behaves like a conventional DAO for now, it will gradually adopt iDAO, coDAO, and Full NFT features, showing participants how a typical DAO can evolve into a more interactive structure under the LazAI protocol.',
  // },
  // {
  //   question: 'What is the Loyalty Points Program, and how do I participate?',
  //   answer:
  //     'The Loyalty Points Program parallels the notion of “contribution points” from LazAI’s protocol, initially dispensing points via a simplified official Bot evaluation. Over time, this approach introduces users to LazAI’s deeper mutual-evaluation model. By contributing to PicWiz DAO events and verifying your input, you accumulate loyalty points that may unlock advanced features or perks in the future. It is a community positive incentive program for lazAI network participants. You can participate by visiting the get started entrance on the lazAI official  website.',
  // },
  // {
  //   question: 'Which roles exist for LazAI participants?',
  //   answer:
  //     'Participants of LazAI can be understood as the type of participating roles of LazAI organization form iDAO. Users can take on various roles:\nBuilders: Contribute labor or resources, earning “contribution points.”\nInvestors: Provide funding for early sub-projects in exchange for partial ownership or future dividends.\nConsumers: Acquire Full NFTs representing products or IP, automatically rewarding prior contributors along the chain.\nCoordinators: Unify multiple iDAOs, bridging tasks or resources for cross-DAO synergy.\nManagers: Oversee collaboration within a single iDAO, guiding participants’ tasks and earning points accordingly.',
  // },
  // {
  //   question: 'Can iDAO include real-world assets (RWA) like finance or gold?',
  //   answer:
  //     'Yes. Any RWA anchoring depends on an oracle and staking logic that surpasses the asset’s value to discourage malicious acts. This parallels how miners in Bitcoin risk losing block rewards if they attempt double-spends. For iDAO, a verifiable identity or authorization generated on-chain based on complete computable behavioral data prevents a single point of risk and helps bridge real-world assets to build trust.',
  // },
  // {
  //   question: 'What about the AI agent (Bot) in LazAI?',
  //   answer:
  //     'In LazAI, AI agent assist at multiple levels:\nWithin iDAO: Gathering user contributions, guiding rating processes, and facilitating cooperation.\nBetween iDAOs: Evaluating potential alliances or synergy pathways.\nBot Marketplace: Third-party or custom AI agents can register, providing specialized features and charging usage fees.<br/>Hence, Bots serve as the operational glue that keeps data flows efficient, ensuring no one is overwhelmed by repetitive tasks.',
  // },
  // {
  //   question:
  //     'If a partner already has its own AI agent, how does it integrate with LazAI?',
  //   answer:
  //     'They can register in LazAI’s generate data marketplace by LazAI constraints. By providing parameters such as the object characteristics of the partner and opening its own interface, it is used to earn by the API of LazAI agent. This ensures it fits with iDAO, generate data marketplace, and Full NFTs for synergy, letting them monetize or share those capabilities within the broader LazAI ecosystem.',
  // },
  // {
  //   question: 'How are copyrights or creative assets managed in iDAO?',
  //   answer:
  //     'Such assets can be anchored with a traditional NFT for broad verification. Meanwhile, the generated data marketplace captures the creative steps, data, or roles of each contributor. Full NFTs then unify those snapshots into a single on-chain container, establishing a deeper, living record of how that copyrighted work was produced, attributed, or shared.',
  // },
  // {
  //   question: 'Must existing NFTs be converted into Full NFTs?',
  //   answer:
  //     'Not necessarily. Existing NFTs can remain as they are. Full NFTs mainly provide a more complex, relational view that can integrate or reorganize multiple standard NFTs. For advanced synergy or user engagement, AI agents can assist in analyzing or linking them without forcing any conversions.\nThank you for exploring LazAI’s vision with us. We hope these FAQ insights clarify how iDAO, generate data marketplace, and Full NFTs redefine AI alignment and real-world asset integration. For further details or to join our growing community, visit our official resources or connect with us online—together, we can shape a more transparent, perspective-driven AI future.”',
  // },
  // {
  //   question: 'What is PicWiz?',
  //   answer:
  //     'In this answer, it is necessary to explain which parts of PicWiz are idao, how it interacts with cdao, what information is considered NFT, how this product is related to POV, and what group consensus it represents.',
  // },
];

export default FAQ;
