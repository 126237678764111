import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import styles from './BottomModules.module.css';
import HoverButton from 'components/HoverButton';
import TextAnimation from 'components/TextAnimation';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import AnimatedSVG from 'components/AnimatedSVG';
import { Button } from '@mui/material';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 100 / 55;
  position: relative;
  z-index: 1;
  gap: 48px;
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 556px;
  position: relative;
  z-index: 1;
  gap: 48px;
`;

const BlackLogo = styled.div`
  width: 60%;
  height: auto;
`;

const MobileBlackLogo = styled.div`
  width: 240px;
  height: auto;
`;

const GradientButton = styled(Button)`
  position: relative;
  border: 2px solid white;
  padding: 20px 28px;
  cursor: pointer;
  color: white;
  font-weight: 700;
  font-size: 20px;
  font-family: 'Syne', sans-serif;
  text-transform: none;
  width: 310px;
  height: 62px;
  border-radius: 31px;
  overflow: hidden;

  &:hover {
    border-color: transparent;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-image: linear-gradient(90deg, #ff229f, #ff7300);
    transition: height 0.3s ease;
    z-index: -1;
  }

  &:hover::after {
    height: 100%; /* 鼠标悬停时使伪元素高度变为100% */
  }
`;

interface SectionProps {
  id?: string;
  className?: string;
}

export default function BottomModules() {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  const variants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
      filter: 'blur(2px)',
    },
    visible: (i: number) => ({
      opacity: 1,
      scale: 1,
      filter: 'none',
      transition: {
        delay: i * 0.5,
        duration: 1,
        ease: 'easeIn',
      },
    }),
  };

  const logoVariants = {
    hidden: {
      opacity: 0,
      filter: 'blur(10px)',
    },
    visible: (i: number) => ({
      opacity: 1,
      filter: 'none',
      transition: {
        delay: i * 0.5,
        duration: 1.5,
        ease: 'easeIn',
      },
    }),
  };

  const handleButtonClick = () => {
    window.open('https://t.me/lazai_global', '_blank');
  };

  return isMobile ? (
    <MobileWrapper className={styles.box} ref={ref}>
      {inView && (
        <TextAnimation
          text={`｢AI & Humanity\nAligned On-chain｣`}
          fontSize="28px"
          color="#000"
        />
      )}
      <motion.div
        variants={variants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        custom={2}
        style={{
          zIndex: 2,
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <MobileBlackLogo>
          <AnimatedSVG />
        </MobileBlackLogo>
      </motion.div>
      <motion.div
        variants={variants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        custom={3}
        style={{
          zIndex: 2,
        }}
      >
        <HoverButton
          text="Join LazAI Community"
          fontSize="16px"
          width="257px"
          height="44px"
          borderRadius="22px"
          onClick={handleButtonClick}
        />
      </motion.div>
    </MobileWrapper>
  ) : (
    <Wrapper className={styles.box} ref={ref}>
      {inView && (
        <TextAnimation
          text="「AI & Humanity Aligned On-chain」"
          fontSize="48px"
          color="#000"
        />
      )}
      <motion.div
        variants={logoVariants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        custom={2}
        style={{
          zIndex: 2,
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <BlackLogo>
          <AnimatedSVG />
        </BlackLogo>
      </motion.div>
      <motion.div
        variants={variants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        custom={3}
        style={{
          zIndex: 2,
        }}
      >
        <GradientButton onClick={handleButtonClick}>
          Join LazAI Community
        </GradientButton>
      </motion.div>
    </Wrapper>
  );
}
