import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import TextAnimation from 'components/TextAnimation';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { LeftMaskSvg, RightMaskSvg } from 'assets/img';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 1;
  margin-top: 100px;
  margin-bottom: 150px;
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-top: 60px;
`;

const ContentBox = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  width: 80%;
  min-width: 1200px;
  max-width: 1400px;
`;

const MobileContentBox = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  width: 100%;
`;

const Title = styled(Typography)`
  color: #fff;
  font-family: Syne;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  margin-left: 14px;
  height: 121.81px;
`;

const ImageBox = styled(motion.div)`
  display: flex;
  position: relative;
  z-index: 1;
  width: 45%;
  aspect-ratio: 100 / 100;
  margin-top: 40px;
  margin-bottom: 76px;
`;

const MobileImageBox = styled(motion.div)`
  display: flex;
  position: relative;
  z-index: 1;
  width: 75%;
  aspect-ratio: 100 / 100;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const TextContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 80%;
  min-width: 1200px;
  max-width: 1400px;
  display: flex;
  flex-wrap: wrap;
  padding: 40px;
  box-sizing: border-box;
  z-index: 9999;
`;

const MobileTextContainer = styled.div`
  position: relative;
  width: 100vw;
  z-index: 9999;
`;

const TextBlock = styled(motion.div)<{
  position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
}>`
  width: 100%;
  max-width: 420px;
  position: absolute;
  ${({ position }) => position === 'top-left' && 'top: 60px; left: 0;'}
  ${({ position }) => position === 'top-right' && 'top: 60px; right: -80px;'}
  ${({ position }) =>
    position === 'bottom-left' && 'bottom: 100px; left: -70px;'}
  ${({ position }) => position === 'bottom-right' && 'bottom: 0; right: 0;'}
`;

const MobileTextBlock = styled.div`
  width: 80%;
  position: relative;
  text-align: left;
  padding: 20px 24px;
`;

const ItemTitle = styled(Typography)`
  color: #fff;
  font-family: Syne;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
`;

const Description = styled(Typography)`
  color: #c4c4c4;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
`;

const MobileDescription = styled(Typography)`
  color: #c4c4c4;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
`;

const Line = styled(motion.div)`
  height: 4px;
  background: var(
    --red-gradient,
    linear-gradient(90deg, #ff229f 0%, #ff4570 53.5%, #ff7300 100%)
  );
`;

const LeftMask = styled(LeftMaskSvg)`
  width: 494px;
  height: auto;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
`;

const Divider = styled(motion.div)`
  height: 1px;
  background-color: #fff;
  margin: 8px 0;
`;

const AnimationBox = styled(motion.div)`
  width: 100%;
`;

interface SectionProps {
  id?: string;
  className?: string;
}

const Mechanism: React.FC<SectionProps> = ({ id, className }) => {
  const sliderRef = useRef<Slider>(null);

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const variants = {
    hidden: {
      opacity: 0,
      y: 80,
      filter: 'blur(2px)',
    },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      filter: 'none',
      transition: {
        delay: i * 0.2,
        duration: i === 0 ? 3 : 0.5,
        ease: 'easeIn',
      },
    }),
  };

  const dividerVariants = {
    hidden: {
      width: '0%',
    },
    visible: (i: number) => ({
      width: '100%',
      transition: {
        delay: i * 0.2,
        duration: 0.5,
        ease: 'easeInOut',
      },
    }),
  };

  const lineVariants = {
    hidden: {
      width: '0%',
    },
    visible: {
      width: '56px',
      transition: {
        duration: 1,
        ease: 'easeInOut',
      },
    },
  };

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    cssEase: 'linear',
  };

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay();
    }
  }, [inView]);

  return isMobile ? (
    <MobileWrapper ref={ref} id={id} className={className}>
      {inView ? (
        <TextAnimation text={`LazAI Blockchain\nSolution`} fontSize="36px" />
      ) : (
        <Title></Title>
      )}
      <Line
        variants={lineVariants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
      />
      <MobileContentBox>
        <MobileImageBox
          variants={variants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          custom={0}
        >
          <video
            src={'/video/mechanism.mp4'}
            autoPlay
            loop
            muted
            playsInline
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </MobileImageBox>
        <MobileTextContainer>
          <Slider ref={sliderRef} {...settings}>
            <MobileTextBlock>
              <ItemTitle>Application Layer</ItemTitle>
              <MobileDescription>
                The application layer offers users a wide range of decentralized
                applications (DApps), including AIAgent, Marketplace, Meme Coin
                launch platform, DeFAI, and other services.
              </MobileDescription>
            </MobileTextBlock>

            <MobileTextBlock>
              <ItemTitle>AI Execution Extension Layer</ItemTitle>
              <MobileDescription>
                Built on the high-performance LazAI Blockchain Network, the AI
                Execution Extension Layer serves as an efficient and reliable
                decentralized processing platform for the release and
                circulation of AI assets.
              </MobileDescription>
            </MobileTextBlock>

            <MobileTextBlock>
              <ItemTitle>Interaction Layer</ItemTitle>
              <MobileDescription>
                The interaction layer delivers robust protocols and tools to
                support diverse user interactions with LazChain, its protocols,
                and associated platforms.
              </MobileDescription>
            </MobileTextBlock>
          </Slider>
        </MobileTextContainer>
      </MobileContentBox>
    </MobileWrapper>
  ) : (
    <Wrapper ref={ref} id={id} className={className}>
      <LeftMask />
      {inView ? (
        <TextAnimation text="LazAI Blockchain Solution" fontSize="48px" />
      ) : (
        <Title></Title>
      )}
      <Line
        variants={lineVariants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
      />
      <ContentBox>
        <ImageBox
          variants={variants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          custom={0}
        >
          <video
            src={'/video/mechanism.mp4'}
            autoPlay
            loop
            muted
            playsInline
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </ImageBox>
        <TextContainer>
          <TextBlock position="top-right">
            <AnimationBox
              variants={variants}
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              custom={3}
            >
              <ItemTitle>Application Layer</ItemTitle>
            </AnimationBox>
            <Divider
              variants={dividerVariants}
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              custom={2}
            />
            <AnimationBox
              variants={variants}
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              custom={4}
            >
              <Description>
                The application layer offers users a wide range of decentralized
                applications (DApps), including AIAgent, Marketplace, Meme Coin
                launch platform, DeFAI, and other services.
              </Description>
            </AnimationBox>
          </TextBlock>

          <TextBlock position="bottom-left">
            <AnimationBox
              variants={variants}
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              custom={6}
            >
              <ItemTitle>
                AI Execution
                <br />
                Extension Layer
              </ItemTitle>
            </AnimationBox>
            <Divider
              variants={dividerVariants}
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              custom={5}
            />
            <AnimationBox
              variants={variants}
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              custom={7}
            >
              <Description>
                Built on the high-performance LazAI Blockchain Network, the AI
                Execution Extension Layer serves as an efficient and reliable
                decentralized processing platform for the release and
                circulation of AI assets.
              </Description>
            </AnimationBox>
          </TextBlock>

          <TextBlock position="bottom-right">
            <AnimationBox
              variants={variants}
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              custom={9}
            >
              <ItemTitle>Interaction Layer</ItemTitle>
            </AnimationBox>
            <Divider
              variants={dividerVariants}
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              custom={8}
            />
            <AnimationBox
              variants={variants}
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              custom={10}
            >
              <Description>
                The interaction layer delivers robust protocols and tools to
                support diverse user interactions with LazChain, its protocols,
                and associated platforms.
              </Description>
            </AnimationBox>
          </TextBlock>
        </TextContainer>
      </ContentBox>
    </Wrapper>
  );
};

export default Mechanism;
