import React from 'react';

const AnimatedSVG = () => {
  return (
    <div>
      <object
        type="image/svg+xml"
        data="/assets/img/black-logo.svg"
        width="100%"
        height="100%"
        aria-label="Black Logo"
      >
        您的浏览器不支持 SVG 动画，请升级或更换浏览器。
      </object>
    </div>
  );
};

export default AnimatedSVG;
