import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { NetworkImageSvg, BigLogoSvg } from 'assets/img';
import TextAnimation from 'components/TextAnimation';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import { RightMaskSvg } from 'assets/img';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 1;
  margin-top: 100px;
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;
  margin-top: 60px;
  padding: 60px 24px;
`;

const Title = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: Syne;
  font-size: 48px;
  font-weight: 700;
  height: 121.81px;
`;

const ImageBox = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  min-width: 1200px;
  max-width: 1400px;
  aspect-ratio: 100 / 56;
  position: relative;
  z-index: 1;
  margin-top: 40px;
  margin-bottom: 160px;
  border-radius: 42px;
  overflow: hidden;
`;

const MobileImageBox = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  z-index: 1;
`;

const ContentBox = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  gap: 40px;
  z-index: 1;
`;

const Logo = styled(BigLogoSvg)`
  width: 80%;
  height: auto;
`;

const MobileLogo = styled(BigLogoSvg)`
  width: 200px;
  height: auto;
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TopText = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: Syne;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -0.6px;
`;

const MobileTopText = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: Syne;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.6px;
`;

const BottomText = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: Syne;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -0.6px;
  text-decoration-line: underline;
`;

const MobileBottomText = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: Syne;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.6px;
  text-decoration-line: underline;
`;

const RightMask = styled(RightMaskSvg)`
  width: 770px;
  height: auto;
  position: absolute;
  z-index: 1;
  right: 0;
`;

interface SectionProps {
  id?: string;
  className?: string;
}

const Network: React.FC<SectionProps> = ({ id, className }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const { ref, inView } = useInView({
    threshold: isMobile ? 0.6 : 0.1,
    // triggerOnce: true,
  });

  useEffect(() => {
    if (videoRef.current) {
      if (inView) {
        const timer = setTimeout(() => {
          videoRef.current?.play().catch((err) => {
            console.error('Video play error:', err);
          });
        }, 2000);

        return () => {
          clearTimeout(timer);
        };
      } else {
        videoRef.current.pause();
      }
    }
  }, [inView]);

  const fadeInVariants = {
    hidden: {
      opacity: 0,
      filter: 'blur(10px)',
    },
    visible: {
      opacity: 1,
      filter: 'none',
      transition: {
        duration: 1,
        ease: 'easeIn',
      },
    },
  };

  const textFadeInVariants = {
    visible: {
      opacity: 1,
      scale: 1,
      filter: 'none',
    },
    hidden: {
      opacity: 0,
      scale: 0.8,
      filter: 'blur(10px)',
      transition: {
        delay: 1,
        duration: 1,
        ease: 'easeOut',
      },
    },
  };

  return isMobile ? (
    <MobileWrapper ref={ref} id={id} className={className}>
      <MobileImageBox
        variants={fadeInVariants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
      >
        <video
          ref={videoRef}
          src={'https://files.lazai.network/horizontal.mp4'}
          // poster={require('assets/img/mobile_title_page.png')}
          autoPlay={false}
          loop
          muted
          playsInline
          style={{
            width: '100%',
            height: '100vh',
            objectFit: 'cover',
            position: 'absolute',
          }}
        />
        <ContentBox
          variants={textFadeInVariants}
          initial="hidden"
          animate={inView ? 'hidden' : 'visible'}
        >
          <MobileLogo />
          <TextAnimation text="LazAI Story" fontSize="36px" />
          <TextBox>
            <MobileTopText>AI-Powered</MobileTopText>
            <MobileBottomText>Scientific Research...</MobileBottomText>
          </TextBox>
        </ContentBox>
      </MobileImageBox>
    </MobileWrapper>
  ) : (
    <Wrapper ref={ref} id={id} className={className}>
      <RightMask />
      {inView ? (
        <TextAnimation text="LazAI Story" fontSize="48px" />
      ) : (
        <Title></Title>
      )}
      <ImageBox
        variants={fadeInVariants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
      >
        <video
          ref={videoRef}
          src={'https://files.lazai.network/vertical.mp4'}
          // poster={require('assets/img/title_page.png')}
          autoPlay={false}
          loop
          muted
          playsInline
          style={{
            width: '100%',
            height: '100vh',
          }}
        />
        <ContentBox
          variants={textFadeInVariants}
          initial="visible"
          animate={inView ? 'hidden' : 'visible'}
        >
          <Logo />
          <TextBox>
            <TopText>AI-Powered</TopText>
            <BottomText>Scientific Research...</BottomText>
          </TextBox>
        </ContentBox>
      </ImageBox>
    </Wrapper>
  );
};

export default Network;
